import React, { useEffect, useState } from "react";
import {
    IonContent,
    IonPage,
    IonInput,
    IonButton,
    IonLabel,
    IonItem,
    IonToast,
    IonLoading,
    IonText,
    IonCol,
    IonRow,
} from "@ionic/react";
import HeadFoot from "./Head";
import Foot from "./Foot";
import Authservice from "../Services/user.service";
import CommonModal from "../components/popipInfo";
import { useHistory, useLocation } from "react-router";

const VerifyEmail: React.FC = () => {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [userId, setUserID] = useState("")
    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")
    const history = useHistory()
    const location = useLocation();
    const [token, setToken] = useState("");




    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tkn = searchParams.get("tkn"); // Get token from query param
        if (tkn) {
            Authservice.getUserInfo(tkn)
                .then((response) => {
                    console.log(response.data)
                    setEmail(response.data.email)
                    setUserID(response.data.userId)
                })
                .catch((error) => {
                    setLoading(false)
                    alert(error)
                });
            setToken(tkn);
        }
    }, [location.search]);

    const sendOtp = async () => {
        setLoading(true);
        // Authservice.getEmailOtp(userId, null)
        //     .then((response) => {
        //         if (response.status === 200) {
        //             setTimeout(() => {
        //                 setLoading(false);
        //                 setOtpSent(true);
        //                 setCommonModalMesg("OTP sent to your email.");
        //                 setCommonmodaltitle("Information")
        //                 setCommonmodal(true)
        //             }, 1500);
        //         } else {
        //             setLoading(false);
        //             showToastMessage("Something went wrong!");
        //         }

        //     })
        //     .catch((error) => {
        //         setLoading(false)
        //         alert(error)
        //     });
        Authservice.VerigyMailuser(userId, null, email)
            .then((response) => {
                if (response.status === 200) {
                    setTimeout(() => {
                        setLoading(false);
                        setCommonModalMesg("Email verified successfully!");
                        setCommonmodaltitle("Information")
                        setCommonmodal(true)
                    }, 1500);
                } else {
                    setLoading(false);
                    showToastMessage("Something went wrong!");
                }

            })
            .catch((error) => {
                setLoading(false)
                alert(error)
            });

    };

    const verifyOtp = async () => {
        if (!otp) {
            setCommonModalMesg("Please enter the OTP.");
            setCommonmodaltitle("Error")
            setCommonmodal(true)
            return;
        }
        Authservice.verifyotp(email, otp, userId, null)
            .then((response) => {
                if (response.data === "success") {
                    setLoading(true);
                    setTimeout(() => {
                        setLoading(false);
                        setCommonModalMesg("Email verified successfully!");
                        setCommonmodaltitle("Information")
                        setCommonmodal(true)
                    }, 1500);
                } else if (response.data === "invalid") {
                    setCommonModalMesg("Invalid otp");
                    setCommonmodaltitle("Error")
                    setCommonmodal(true)
                } else {
                    setCommonModalMesg("Something Went Wrong");
                    setCommonmodaltitle("Error")
                    setCommonmodal(true)
                }

            })
            .catch((error) => {
                setLoading(false)
                alert(error)
            });
    };

    const showToastMessage = (message: string) => {
        setToastMessage(message);
        setShowToast(true);
    };

    const modalClose = () => {
        if (CommonModalMesg === "Email verified successfully!") {
            history.push("/")
        } else {
            setCommonmodal(false)
        }
    }

    return (
        <IonPage>
            <IonContent>
                <HeadFoot User={"login"} Role={'Role'} Name={"Home,Verify Email"} />
                <div className="page_content_padding container_bg">
                    <div className="verify-email-wrapper">
                        {!otpSent ? (
                            <div style={{ height: "fit-content" }}>
                                <IonRow>
                                    <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                        <IonLabel className="label_align">
                                            Email <span style={{ color: "red" }}>*</span>
                                        </IonLabel>
                                        <IonInput className="select_user"
                                            clearOnEdit={false}
                                            readonly
                                            value={email}
                                        />
                                    </IonCol>
                                </IonRow>
                                <IonRow >
                                    <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                        <IonButton
                                            type="submit"
                                            className="btn_v2"
                                            onClick={sendOtp}
                                        >
                                            <span className="button_padding_v2">
                                                {/* Get Otp */}
                                                Verify
                                            </span>
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </div>
                        ) : (
                            <>
                                <IonRow>
                                    <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                        <IonLabel className="label_align">
                                            Enter OTP <span style={{ color: "red" }}>*</span>
                                        </IonLabel>
                                        <IonInput className="select_user"
                                            clearOnEdit={false}
                                            onIonChange={(e) => setOtp(e.detail.value!)}
                                        />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                        <IonButton
                                            type="submit"
                                            className="btn_v2"
                                            onClick={verifyOtp}
                                        >
                                            <span className="button_padding_v2">
                                                Verify Otp
                                            </span>
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonText className="resend-text">
                                    Didn’t receive OTP?{" "}
                                    <a onClick={sendOtp} className="resend-button">
                                        Resend OTP
                                    </a>
                                </IonText>
                            </>
                        )}
                    </div>
                </div>

                <IonLoading isOpen={loading} message="Please wait..." />
                <IonToast isOpen={showToast} message={toastMessage} duration={2000} onDidDismiss={() => setShowToast(false)} />
                <Foot />
            </IonContent>
            <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />

        </IonPage>
    );
};

export default VerifyEmail;
