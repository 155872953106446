import {
    IonCol,
    IonContent,
    IonIcon,
    IonItemDivider,
    IonPage,
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
    IonText,
    IonGrid,
    IonTextarea,
    IonImg,
    IonLoading,
    IonSlide,
    IonSlides,
    useIonAlert,
    IonPopover,
    IonList,
    IonSelect,
    IonSelectOption,
    IonSearchbar,
    IonAlert,
    IonInput,
    IonCheckbox,
} from "@ionic/react";
import React, { useMemo, useRef } from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router";
import Authservice from "../../Services/user.service";
import UserService from "../../Services/auth.service";
import Foot from "../Foot";
import Head from "../Head";
import { Modal } from 'react-bootstrap';
import { addCircle, addCircleSharp, addOutline, arrowBack, arrowBackOutline, chevronDownOutline, close, closeCircle, ellipsisVerticalCircleOutline, filter, remove, removeCircle, shareSocialSharp, trash } from "ionicons/icons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { Capacitor } from "@capacitor/core";
import TempleAdmDet from "./TempDetails";
import Addtemp from "./addtemple";
import Select, { components } from 'react-select';
import { stat } from "fs";
import { error } from "console";
import AdminHead from "./adminHead";
import Sidebar from "./sidebar";
import customTableStyle from "../../components/CustomTableStyle";
import CommonDataTable from "../../components/datatable";
import Anthropic from "@anthropic-ai/sdk";
import { set } from "react-hook-form";
import moment from "moment";
import CommonModal from "../../components/popipInfo";
import * as XLSX from "xlsx";


const TempleComponent = ({ id, isUserSide, isedit, goBack }: any) => {

    const [usertoken] = useState(sessionStorage.getItem("token"));
    const [userId]: any = useState(sessionStorage.getItem("UserId"));
    const [facilities, setFacilities]: any = useState("");
    const [summary, SetSummary]: any = useState("");
    const [summaryId, SetSummaryId]: any = useState("");
    const [Administration, setAdministration]: any = useState("");
    const [review, setReview]: any = useState("");
    const [aerial, setAerial]: any = useState("");
    const [festival, setFestival]: any = useState("");
    const [poojaoffer, setPoojaOffer]: any = useState("");
    const [lat, setLat]: any = useState("");
    const [long, setLong]: any = useState("");
    const [dressCode, setDressCode] = useState("");
    const [ServiceCharge, setServiceCharge]: any = useState([])
    const [serviceDes, setserviceDes]: any = useState("")
    const [timeOfWorship, setTimeOfWorship] = useState("");
    const [structure, setStructure] = useState("");
    const [carvings, setCarvings] = useState("");
    const [temName, setTemName]: any = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const location: any = useLocation();
    const templeCode = id;
    const [transport, setTransport] = useState(""); // State for Transport
    const [locationInfo, setLocationInfo] = useState(""); // State for Location
    const [image, setImage] = useState<any[]>([]);
    const [imageFiles, setImageFiles]: any = useState([]);
    const [videolink, setVideolink]: any = useState([]);
    const [formErrors, setFormErrors] = useState<any>({});
    const imageinputref = useRef<HTMLInputElement>(null);
    const videoInputRef = useRef<any>(null);
    const liveVideoInputRef = useRef<any>(null);
    const [tempDet, setTempdet]: any = useState()
    const [maindeity, setmaindeity]: any = useState()
    const [otherDeities, setotherDeities]: any = useState()
    const [showModal, setShowModal] = useState(false); // Show modal
    const history = useHistory()
    const [showAlert, setShowAlert] = useState(false);
    const [alertmsg, setalertmsg]: any = useState("")
    const [districtOptions, setdistrictoptions]: any = useState([])
    const [panchayOptions, setpanchayoptions]: any = useState([])
    const [district, setdistrict]: any = useState("")
    const [state, setState]: any = useState("KL")
    const [country, setCountry]: any = useState("IN")
    const [panchayat, setpanchayat]: any = useState("")
    const [bookDetailsModal, setBookDetailsModal] = useState(false)
    const [selectedPoojaRows, setSelectedPoojaRows]: any = useState([])
    const [editPoojaItem, seteditPoojaItem]: any = useState({})
    const [currentPoojaId, setCurrentPoojaId] = useState("")
    const [poojaListTemple, setPoojaListTemple]: any = useState([])
    const [startCity, setStartCity] = useState('');
    const [transportDetails, setTransportDetails]: any = useState("");
    const [loading, setLoading] = useState(false);
    const [transportModal, setTransportModal] = useState(false)
    const anthropic = new Anthropic({
        apiKey: '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',
        dangerouslyAllowBrowser: true
    });
    const [poojaList, setPoojaList]: any = useState([
    ]);
    const [isActive, setIsActive] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [isVetoed, setIsVetoed] = useState(false)
    const [readyPooja, setReadyPooja] = useState(false)
    const [vetoedBy, setVetoedBy] = useState("")
    const [vetoedOn, setVetoedOn] = useState("")
    const [modifiedBy, setModifiedBy] = useState("")
    const [modifiedOn, setModifiedOn] = useState("")
    const [deityOptions, setDeityOptions]: any = useState([])
    const [serviceChrgDetails, setServiceChrgDetails]: any = useState([])
    const oneTimeRender = useRef(false)
    const prasadhamOptions = [
        { value: "1", label: "Yes" },
        { value: "0", label: "No" },
    ]
    const [deity, setDeity]: any = useState("")
    const [adminList, setAdminList] = useState([])
    const [selectedAdmin, setSelectedAdmin]: any = useState("")

    const [showReviewInput, setShowReviewInput] = useState(false);
    const [reviewText, setReviewText] = useState("");
    const [modalopen, setModalOpen] = useState(false)
    const [modalData, setModaldata] = useState()

    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")

    const [districtName, setDistrictName] = useState("")
    const [panchayatName, setPanchayatName] = useState("")
    const [reloadflag, setReloadFlag] = useState(false)

    const [modalOpenFor, setModalOpenFor] = useState("")
    const fileInputRef: any = useRef(null)
    const [deletePoojaCode, setdeletePoojaCode] = useState("")


    const modalColumn = [
        {
            name: "Review",
            selector: (item: any, i: any) => item.review,
            sortable: true,
            width: "85%",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.review}</div>
                </div>
            )
        },
        {
            name: "Review By",
            selector: (item: any, i: any) => item.reviewBy,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.reviewBy}</div>
                </div>
            )
        },

    ]
    const modalColumnAdmin = [
        {
            name: "Active",
            selector: (item: any, i: any) => item.isActive,
            sortable: true,
            width: "100px",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.isActive === null ? "N/A" : item.isActive ? "Yes" : "No"}</div>
                </div>
            )
        },
        {
            name: "Delete",
            width: "100px",
            selector: (item: any, i: any) => item.isDeleted,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.isDeleted === null ? "N/A" : item.isDeleted ? "Yes" : "No"}</div>
                </div>
            )
        },
        {
            name: "Vetoed By",
            width: "100px",
            selector: (item: any, i: any) => item.vetoedBy,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.vetoedBy === null ? "N/A" : item.vetoedBy}</div>
                </div>
            )
        },
        {
            name: "Vetoed On",
            width: "100px",
            selector: (item: any, i: any) => item.vetoedon,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.vetoedon === null ? "N/A" : item.vetoedon}</div>
                </div>
            )
        },
        {
            name: "Review",
            selector: (item: any, i: any) => item.review,
            sortable: true,
            width: "50%",
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.review}</div>
                </div>
            )
        },
        {
            name: "Review By",
            selector: (item: any, i: any) => item.reviewBy,
            sortable: true,
            cell: (item: any) => (
                <div >
                    <div style={{ cursor: "pointer" }} >{item.reviewBy}</div>
                </div>
            )
        },

    ]

    useEffect(() => {
        console.log(templeCode, "isUserSide")
        if (oneTimeRender.current == false) {
            oneTimeRender.current = true
            getalldis()
            if (!isUserSide) {
                if (!isedit) {
                    setLoading(true)
                    setTempdet(
                        {
                            templesDto: {
                                "templeName": "",
                                "mainDeity": "",
                                "antiquity": "Ancient",
                                "sreeKovil": "Traditional Kerala Style",
                                "timeOfWorship": "",
                                "poojaOfferings": "",
                                "festivals": "",
                                "group": "Sub-group E",
                                "briefHistory": "",
                                "structures": "",
                                "carvings": "",
                                "otherDeities": "",
                                "templeStaff": "Temple committee",
                                "remarks": "Known for its serene atmosphere",
                                "vetoedBy": "",
                                "country": "IN",
                                "state": "KL",
                                "district": "PAL",
                                "panchayat": "PAL",
                                "dressCode": "",
                                "address": "Kerala",
                                "geoPosition": "",
                                "facilities": "",
                                "templeLocation": ""
                            }
                        })
                    getServiceCharge("", "")
                }
                else {
                    templeDetails(id);
                }
            } else {
                templeDetails(id);
            }
            showalldeity()
        }
    }, [])

    const getUsersForTempleAdmin = (tempAdmin: any) => {
        console.log(tempAdmin)
        Authservice.getUsersForTempleAdmin(usertoken, userId, usertoken).then((response) => {
            if (tempAdmin) {
                response.data.map((item: any) => {
                    if (item.userId === tempAdmin) {
                        setSelectedAdmin({ label: item.firstName + ` ${item.lastName || ""}`, value: item.userId })
                    }
                })
            }
            setAdminList(response.data.map((e: any) => { return { label: e.firstName + ` ${e.lastName || ""}`, value: e.userId } }))
        })
            .catch((error: any) => {
                alert(error)
                setShowLoading(false);
            });
    }

    const showalldeity = () => {
        setShowLoading(true);
        Authservice.Showdeity(usertoken, userId, usertoken).then((response) => {
            console.log(response.data);
            setDeityOptions(response.data.map((e: any) => { return { label: e.deityName, value: e.deityCode } }));
            setShowLoading(false);
        })
            .catch((error: any) => {
                alert(error)
                setShowLoading(false);
            });
    }
    const modalClose = () => {
        if (reloadflag) {
            window.location.reload()
        } else {
            setCommonmodal(false)
            if (modalOpenFor === "TempleTransport") {
                setTransportModal(true)
            }
        }
    }
    const openreviewModal = () => {
        // Authservice.getTempleReviews(userId, usertoken, usertoken).then((res: any) => {
        //     if (res.data !== "" && res.data !== null) {
        // setModaldata(res.data)
        setModalOpen(true)
        //     }
        // })
        //     .catch((error: any) => {
        //         console.log(error, "error")
        //         setShowLoading(false)
        //     })
    }

    const handleSubmitReview = async () => {
        if (!reviewText.trim()) {
            alert("Please enter a review before submitting.");
            return;
        }
        const data = {
            "code": templeCode,
            "review": reviewText,
            "category": "Temple",
            "isActive": false,
            "isDeleted": false,
            "isVetoed": false
        }
        Authservice.createOrUpdateTempleReviews(userId, usertoken, usertoken, data).then((response: any) => {
            console.log(response);
            setReviewText(""); // Clear textarea
            setShowReviewInput(false); // Hide input after submission
            setModalOpen(false)
            if (response.status === 200) {
                setCommonmodal(true)
                setCommonmodaltitle("Information")
                setCommonModalMesg("Review submitted successfully!");
            }

        })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });

    };



    const poojaColumn: any = [
        {
            name: "Pooja Name",
            selector: (item: any) => item.PoojaName ?? item.poojaName ?? "",
            sortable: true,
            cell: (item: any, i: any) => (
                <div style={{ cursor: "pointer" }} onClick={(e) => handleSelectedRowsChange(item, i, e)}>
                    {item.PoojaName ?? item.poojaName ?? ""}
                </div>
            ),
        },
        {
            name: "Pooja Description",
            selector: (item: any) =>
                item.PoojaDesc ?? item.poojaDesc ?? item.PoojaDescription ?? item.poojaDescription ?? "",
            sortable: true,
            cell: (item: any, i: any) => (
                <div style={{ cursor: "pointer" }} onClick={(e) => handleSelectedRowsChange(item, i, e)}>
                    {item.PoojaDesc ?? item.poojaDesc ?? item.PoojaDescription ?? item.poojaDescription ?? ""}
                </div>
            ),
        },
        {
            name: "Time of Pooja",
            selector: (item: any) => item.TimeofPooja ?? item.timeOfPooja ?? "",
            sortable: true,
            cell: (item: any, i: any) => (
                <div style={{ cursor: "pointer" }} onClick={(e) => handleSelectedRowsChange(item, i, e)}>{item.TimeofPooja ?? item.timeOfPooja ?? ""}</div>
            ),
        },
        {
            name: "Cost",
            selector: (item: any) => item.Amount ?? item.cost ?? "",
            sortable: true,
            cell: (item: any, i: any) => <div style={{ cursor: "pointer" }} onClick={(e) => handleSelectedRowsChange(item, i, e)}>{item.Amount ?? item.cost ?? ""}</div>,
        },
        {
            name: "Deity Name",
            selector: (item: any) => item.Deities ?? item.deityName ?? "",
            sortable: true,
            cell: (item: any, i: any) => <div style={{ cursor: "pointer" }} onClick={(e) => handleSelectedRowsChange(item, i, e)}>{item.Deities ?? item.deityName ?? ""}</div>,
        },
        {
            name: "Action",
            sortable: true,
            cell: (item: any, i: any) => <div style={{ cursor: "pointer" }} >
                <IonIcon icon={trash} size="medium" onClick={() => openDeleteAlert(item.poojaCode)} />
            </div>,
        },
    ];

    // const poojaColumn: any = [
    //     {
    //         width: "50px",
    //         cell: (item: any, i: any) => (
    //             <div >
    //                 <IonCheckbox checked={item.selected} onIonChange={(e) => handleSelectedRowsChange(item, i, e)} />
    //             </div>
    //         )
    //     },
    //     {
    //         name: "S.No",
    //         selector: (item: any, i: any) => item.serial,
    //         sortable: true,
    //         width: "100px",
    //         cell: (item: any) => (
    //             <div >
    //                 <div style={{ cursor: "pointer" }}>{item.serial}</div>
    //             </div>
    //         )
    //     },
    //     {
    //         name: "Pooja Code",
    //         selector: (item: any) => item.poojaCode,
    //         sortable: true,
    //         // width: "400px",
    //         cell: (item: any) => (
    //             <div >
    //                 <div style={{ cursor: "pointer" }} >{item.poojaCode}</div>
    //             </div>
    //         )
    //     },
    //     {
    //         name: "Pooja Name",
    //         selector: (item: any) => item.name,
    //         sortable: true,
    //         // width: "400px",
    //         cell: (item: any) => (
    //             <div >
    //                 <div style={{ cursor: "pointer" }} >{item.name}</div>
    //             </div>
    //         )
    //     },
    //     // {
    //     //   name: "Pooja Description",
    //     //   selector: (item: any) => item.poojaOfferings.description,
    //     //   sortable: true,
    //     //   // width: "400px",
    //     //   cell: (item: any) => (
    //     //     <div >
    //     //       <div style={{ cursor: "pointer" }} >{item.poojaOfferings.description}</div>
    //     //     </div>
    //     //   )
    //     // },
    //     // {
    //     //   name: "Pooja Rate",
    //     //   selector: (item: any) => item.c,
    //     //   sortable: true,
    //     //   // width: "400px",
    //     //   cell: (item: any) => (
    //     //     <div >
    //     //       <div style={{ cursor: "pointer" }} >{item.cost}</div>
    //     //     </div>
    //     //   )
    //     // },
    //     // {
    //     //   name: "Time Of Pooja",
    //     //   selector: (item: any) => item.timeOfPooja,
    //     //   sortable: true,
    //     //   // width: "400px",
    //     //   cell: (item: any) => (
    //     //     <div >
    //     //       <div style={{ cursor: "pointer" }} >{item.timeOfPooja}</div>
    //     //     </div>
    //     //   )
    //     // },
    //     // {
    //     //   name: "Daily Perform Count",
    //     //   selector: (item: any) => item.dailyPerformCount,
    //     //   sortable: true,
    //     //   // width: "400px",
    //     //   cell: (item: any) => (
    //     //     <div >
    //     //       <div style={{ cursor: "pointer" }} >{item.dailyPerformCount}</div>
    //     //     </div>
    //     //   )
    //     // },
    // ]




    const Tab_items: any = [
        "Admin Controller",
        "Summary",
        "About & History",
        "Deity",
        "Location",
        "Facilities & Dress Code",
        "Time Of Worship",
        "Pooja Offerings",
        "Festivals",
        "Structures & Carvings",
        "Review",
        "Administration",
        "Gallery",
        "Vedic Dharmic Contribution"
    ];

    const [menuItems, setMenuItems] = useState(
        Tab_items.map((data: any) => ({ name: data, isOpen: false }))
    );

    const center = {
        lat: lat,
        lng: long,
    };

    const mapOption = {
        gestureHandling: "cooperative",
        minZoom: 4
    };

    const targetRef: any = useRef(null);

    const getalldis = () => {
        Authservice.getAdmAllDistricts(userId, usertoken, usertoken).then((response: any) => {
            if (response && response.data !== "") {
                setdistrictoptions(response.data)
                setpanchayat("")
            }
        }).catch((error: any) => {
            console.log(error, "error");
        });
    }
    const getallpan = (e: any) => {
        Authservice.getAllPanchayats(usertoken, userId, usertoken, e).then((response: any) => {
            console.log(response.data, "panchayat")
            if (response && response.data !== "") {
                setpanchayoptions(response.data)
            }
        }).catch((error: any) => {
            console.log(error, "error");
        });
    }
    const getServiceCharge = (item: any, tempAdmin: any) => {
        Authservice.getAdmTempleServiceCharge(usertoken, userId, usertoken).then((response: any) => {
            console.log(response.data, item, "service chrg")
            if (response.data !== null && response.data !== "") {
                setServiceChrgDetails(response.data)
                getUsersForTempleAdmin(tempAdmin)
                setLoading(false)
            }
        })
            .catch((error: any) => {
                console.log(error, "error")
                setLoading(false)
            })
    }


    const serviceChrgUpdate = (item: any, e: any) => {
        const value = Number(e); // Ensures proper conversion

        setServiceCharge((prev: any) =>
            prev.some((chrgItem: any) => chrgItem.serviceCode === item.serviceCode)
                ? prev.map((chrgItem: any) =>
                    chrgItem.serviceCode === item.serviceCode
                        ? { ...chrgItem, charges: isNaN(value) ? 0 : value }
                        : chrgItem
                )
                : [...prev, { ...item, charges: isNaN(value) ? 0 : value }]
        );
    }

    useEffect(() => {
        if (!district) return
        getallpan(district)
    }, [district])



    const templeDetails = (id: any) => {
        setLoading(true)
        Authservice.getTempleDetailsAdm(usertoken, userId, usertoken, id, isUserSide).then((response: any) => {
            if (response && response.data !== "") {
                setTempdet(response.data)
                setFacilities(response.data.templesDto.facilities || "");
                setAerial(response.data.templesDto.briefHistory || "");
                setTemName(response.data.templesDto.templeName || "");
                SetSummary(response.data.templeSummaryDto ? response.data.templeSummaryDto.summary : "")
                SetSummaryId(response.data.templeSummaryDto ? response.data.templeSummaryDto.id : "")
                setTimeOfWorship(response.data.templesDto.timeOfWorship || "");
                setStructure(response.data.templesDto.structures || "");
                setModaldata(response.data.templeReviewsDtos || [])
                setCarvings(response.data.templesDto.carvings || "");
                setPoojaOffer(response.data.templesDto.poojaOfferings || "");
                setDressCode(response.data.templesDto.dressCode || "");
                setFestival(response.data.templesDto.festivals || "");
                setLocationInfo(response.data.templesDto.templeLocation || "");
                setpanchayat(response.data.templesDto.panchayat || "");
                setdistrict(response.data.templesDto.district || "");
                setDistrictName(response.data.templesDto.districtName || "")
                setPanchayatName(response.data.templesDto.panchayathName || "")
                setmaindeity(response.data.templesDto.mainDeity || "");
                setotherDeities(response.data.templesDto.otherDeities || "");
                setVideolink(response.data.templesDto.templeVideoLinksDto || []);
                setIsActive(response.data.templesDto.isActive)
                setIsDeleted(response.data.templesDto.isDeleted)
                setModifiedBy(response.data.templesDto.modifiedBy)
                var date: any = new Date(response.data.templesDto.modifiedOn)
                var format = moment(date).format("DD-MM-YYYY")
                setModifiedOn(format)
                if (response.data.templesDto.vetoedBy === null && response.data.templesDto.vetoedOn === null) {
                    setIsVetoed(false)
                }
                else {
                    setIsVetoed(true)
                    var vetoedDate: any = new Date(response.data.templesDto.vetoedOn)
                    var vetoedFormat = moment(vetoedDate).format("DD-MM-YYYY")
                    setVetoedBy(response.data.templesDto.vetoedBy)
                    setVetoedOn(vetoedFormat)
                }
                if (response.data.templesDto.readyForPooja && response.data.templesDto.readyForPooja === "Y") {
                    setReadyPooja(true)
                }

                const img = response.data.carouselDtos.map((e: any) => {
                    return { image: e.imageUrls[0], carouselId: e.id }
                });
                setImage(img)
                getCoordinatesFromAddress(response.data.templesDto.templeLocation)
                setServiceCharge(response.data.templeServiceChargesDto);
                setserviceDes(response.data.templeServiceChargesDto.serviceDescription || "");
                setPoojaListTemple(response.data.templepoojaDtos || "")
                if (response.data.templesDto.geoPosition) {
                    const splitData = response.data.templesDto.geoPosition.split(",");
                    const long = parseFloat(splitData[1]);
                    setLong(long);
                    const lat = parseFloat(splitData[0]);
                    setLat(lat);
                }
                if (!isUserSide) {
                    getServiceCharge(response.data.templeServiceChargesDto, response.data.templesDto.templeAdmin)
                }
                else {
                    setLoading(false)
                }
            }
        }).catch((error: any) => {
            console.log(error, "error");
            setLoading(false)
        });
    };

    const getCoordinatesFromAddress = (address: string) => {
        console.log(address)
        const regex = /@(\d+(\.\d+)?),(\d+(\.\d+)?),/;
        const match = address ? address.match(regex) : "";
        var latitude = match ? parseFloat(match[1]) : 0;
        var longitude = match ? parseFloat(match[3]) : 0;
        setLat(latitude);
        setLong(longitude);
        // const urlParams = address ? new URLSearchParams(address.split("?")[1]);
        // const zoomLevel = urlParams.get("z");
    };

    const openContent = (index: number) => {
        if (index === 10) {
            if (userId) {
                setModalOpen(true)
                return
            }
            else {
                getMessagesByCategory("LoginMessage")
                return
            }
        }
        if (index == 10) {
            openreviewModal()
        }
        if (index === 1 && !menuItems[index].isOpen) {
            getMessagesByCategory("TempleSummary")
        }
        const updatedMenuItems = menuItems.map((item: any, i: number) => ({
            ...item,
            isOpen: index == 10 ? false : i === index ? !item.isOpen : false, // Toggle selected item, close others
        }));
        setMenuItems(updatedMenuItems);
    };

    const openMap = () => {
        // window.open('https://www.google.com/maps/place/' + lat + ',' + long + '/@' + lat + ',' + long + ',');
        window.open('https://www.google.com/maps/search/' + temName);
    };

    const onchangeImage = (e: any) => {
        const files = e.target.files;
        if (files) {
            const newImages = [...image];
            Array.from(files).forEach((file: any) => {
                if (newImages.length < 10 && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "video/mp4")) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        newImages.push({ image: reader.result, id: null });
                        console.log({ image: reader.result, id: null })
                        setImage([...newImages]);
                        setImageFiles([...imageFiles, ...files])
                    };
                    reader.readAsDataURL(file);
                }
            });
        }
    };

    const matchedDeity = deityOptions.find(
        (option: any) => option.value === editPoojaItem.deityCode
    );
    const matchedPrasadham = prasadhamOptions.find(
        (option: any) => option.value === editPoojaItem.prasadamAvailable
    );

    // Handle Video Link Input
    const addVideoInput = (isLive: boolean) => {
        const newVideoArray = [...videolink];
        newVideoArray.push({ videoName: "", videoLink: "", type: 1 });
        setVideolink(newVideoArray);
    };

    const handleVideoNameChange = (e: any, index: number, isLive: boolean) => {
        const newVideoArray = [...videolink];
        newVideoArray[index].videoName = e.target.value;
        setVideolink(newVideoArray);
    };

    const handleVideoLinkChange = (e: any, index: number, isLive: boolean) => {
        const newVideoArray = [...videolink];
        newVideoArray[index].videoLink = e.target.value;
        setVideolink(newVideoArray);
    };

    // Remove Video Link
    const removeVideo = (index: number) => {
        const newVideoArray = [...videolink];
        newVideoArray.splice(index, 1);
        setVideolink(newVideoArray);
    };
    const removeImage = (i: any) => {
        setImage(image.filter((_, index) => index !== i))
        setImageFiles(imageFiles.filter((_: any, index: any) => index !== i))
    }


    const handleAddPooja = () => {
        const newPooja = {
            poojaCode: `P${(poojaList.length + 1).toString().padStart(4, "0")}`,
            timeOfPooja: "",
            dailyPerformCount: 0,
            cost: 0,
            poojaDescription: "",
        };
        setPoojaList([...poojaList, newPooja]); // Add new pooja offering
    };

    const getValueofcharge = (item: any) => {
        const data = ServiceCharge.find((e: any) => e.serviceCode == item.serviceCode)
        return data ? data.charges : 0
    }

    const handleRemovePooja = (index: any) => {
        const updatedPoojaList = poojaList.filter((_: any, i: any) => i !== index);
        setPoojaList(updatedPoojaList); // Remove pooja offering
    };

    const handlePoojaChange = (e: any, field: any) => {
        console.log(e.detail, e.value, "e.detail.value")
        const updatedPoojaList = editPoojaItem;
        updatedPoojaList[field] = e.detail ? e.detail.value : e.value;
        seteditPoojaItem(updatedPoojaList); // Update pooja offering based on field
        console.log(updatedPoojaList)
    };

    const handleInputChange = (label: any, e: any) => {
        console.log(e.detail.value, "e.detail.value")
        if (label === "district") {
            setdistrict(e.detail.value)

        } else if (label === "pan") {
            setpanchayat(e.detail.value)
        }
    }

    const openPoojaModel = () => {
        if (showModal) {
            setShowModal(false)

        } else {
            setShowLoading(true)
            Authservice.getPoojaOfferingDetails(usertoken, userId, usertoken, templeCode, "").then((response: any) => {
                if (response && response.data !== "") {
                    console.log(response.data)
                    // const updatedArr2 = response.data.map((item2: any, i: any) => {
                    //     const match = poojaListTemple.find((item1: any) => item1.poojaCode === item2.poojaCode);
                    //     if (match) {
                    //         return {
                    //             ...item2,
                    //             poojaDescription: match.poojaDescription,
                    //             cost: match.cost,
                    //             timeOfPooja: match.timeOfPooja,
                    //             dailyPerformCount: match.dailyPerformCount,
                    //             selected: true,
                    //serial: i + 1
                    //                      };
                    //     }
                    //     return { ...item2, serial: i + 1 };
                    // });
                    // setPoojaList(updatedArr2);
                    setPoojaList(response.data.poojaOfferingsDto)
                    // const data = response.data.map((e: any, i: any) => {
                    //     return {
                    //         ...e,
                    //         poojaCode: e.poojaCode,
                    //         timeOfPooja: "",
                    //         dailyPerformCount: "",
                    //         cost: "",
                    //         poojaDescription: "",
                    //         serial: i + 1,
                    //         selected: false
                    //     }
                    // })
                    // setPoojaList(data)
                    setShowLoading(false)

                }
            }).catch((error: any) => {
                console.log(error, "error");
                setShowLoading(false)

            });
            setShowModal(true)
        }
    }

    const handleSelectedRowsChange = (selectedRows: any, i: any, e: any) => {
        console.log(e, selectedRows, "selectedRows")
        setCurrentPoojaId(selectedRows.poojaCode);
        seteditPoojaItem(selectedRows)
        setBookDetailsModal(true)
        // }
    };

    const AddpoojaModal = () => {
        setCurrentPoojaId("");
        seteditPoojaItem({
            poojaCode: "",
            poojaName: "",
            timeOfPooja: "",
            dailyPerformCount: "",
            cost: "",
            poojaDescription: "",
            deityCode: "",
            prasadamAvailable: "",
            selected: false
        });
        setBookDetailsModal(true);
    }
    const handleDeselectRow = () => {
        console.log("handleDeselectRow")
        setPoojaList(poojaList.map((e: any) => e.poojaCode === currentPoojaId ? { ...e, selected: false } : e));
        setBookDetailsModal(false)
        setFormErrors({})
    };

    const memoizedPoojaList = useMemo(() => {
        console.log(editPoojaItem)
        return poojaList.map((e: any) =>
            e.poojaCode === currentPoojaId
                ? { ...editPoojaItem, updated: true }
                : e
        );
    }, [poojaList, currentPoojaId, editPoojaItem]);

    const savepoojadetails = () => {
        const errors: any = {};
        if (!editPoojaItem.poojaName) {
            errors.name = "Please enter the Pooja Name"
        }
        if (!editPoojaItem.cost) {
            errors.cost = "Please enter the cost"
        }
        if (!editPoojaItem.timeOfPooja) {
            errors.time = "Please enter the time"
        }
        if (!editPoojaItem.poojaDescription) {
            errors.des = "Please enter the Description"
        }

        if (!editPoojaItem.deityCode) {
            errors.deity = "Please enter the Deity"
        }
        if (!editPoojaItem.prasadamAvailable) {
            errors.prasadham = "Please enter the Prasadam Available"
        }
        setFormErrors(errors)
        console.log(errors, "errorserrors")
        if (Object.keys(errors).length === 0) {
            const updatedPoojaList = currentPoojaId
                ? poojaList.map((e: any) =>
                    currentPoojaId === e.poojaCode ? { ...editPoojaItem, updated: true } : e
                )
                : [...poojaList, { ...editPoojaItem, updated: true }];
            console.log(updatedPoojaList, "updatedPoojaList")
            setPoojaList(updatedPoojaList);
            setBookDetailsModal(false);
        }
    }

    const onsubmit = () => {
        if (!temName) {
            setCommonModalMesg("Please enter the temple name")
            setCommonmodal(true)
            setCommonmodaltitle("Error")
            return
        }
        if (!district) {
            setCommonModalMesg("Please select the district")
            setCommonmodal(true)
            setCommonmodaltitle("Error")
            return
        }
        if (!panchayat) {
            setCommonModalMesg("Please select the panchayat")
            setCommonmodal(true)
            setCommonmodaltitle("Error")
            return
        }
        setLoading(true)
        console.log(temName)
        tempDet.templesDto.templeName = temName;
        tempDet.templesDto.facilities = facilities;
        tempDet.templesDto.briefHistory = aerial;
        tempDet.templesDto.timeOfWorship = timeOfWorship;
        tempDet.templesDto.structures = structure;
        tempDet.templesDto.carvings = carvings;
        tempDet.templesDto.poojaOfferings = poojaoffer;
        tempDet.templesDto.dressCode = dressCode;
        tempDet.templesDto.festivals = festival;
        tempDet.templesDto.templeLocation = locationInfo;
        tempDet.templesDto.panchayat = panchayat || null;
        tempDet.templesDto.district = district;
        tempDet.templesDto.otherDeities = otherDeities;
        tempDet.templesDto.mainDeity = maindeity;
        tempDet.templesDto.isActive = isActive;
        tempDet.templesDto.isDeleted = isDeleted;
        tempDet.templesDto.vetoed = isVetoed;
        tempDet.templesDto.readyForPooja = readyPooja ? "Y" : "N";
        tempDet.templesDto.createdOn = null;
        tempDet.templesDto.templeAdmin = selectedAdmin.value;
        const createTempleDtoRes: any = {
            "templesDto": tempDet.templesDto,
            "templeVideoLinksDto": videolink,
            "templepoojaDtos": [
            ],
            "templeSummaryDto": {
                "id": summaryId,
                "templeCode": null,
                "summary": summary,
            },
            "templeServiceChargesDto": ServiceCharge
        }
        // if (poojaList.some((e: any) => e.selected)) {
        createTempleDtoRes.templepoojaDtos = poojaList
            .filter((e: any) => e.updated)
            .map(({ poojaCode, timeOfPooja, dailyPerformCount, cost, poojaDescription, selected, deityCode, prasadamAvailable, poojaName, deityName }: any) => ({
                poojaCode: isNaN(poojaCode) ? poojaCode : null,
                timeOfPooja,
                poojaName,
                dailyPerformCount: parseInt(dailyPerformCount, 10), // Convert to integer
                cost: parseInt(cost, 10), // Convert to integer
                poojaDescription,
                isDelete: false,
                deityCode: deityCode,
                prasadamAvailable: prasadamAvailable === "1" ? true : false,
                deityName: deityName
            }));
        // }
        console.log(imageFiles)
        Authservice.createandedittemple(usertoken, userId, createTempleDtoRes, imageFiles, isedit).then((response: any) => {
            if (response && (response.data)) {
                // setReloadFlag(true)
                setCommonmodal(true)
                setCommonmodaltitle("Information")
                setShowModal(false)
                if (isedit) {
                    setCommonModalMesg("Temple Updated Successfully");
                } else {
                    setCommonModalMesg("Temple created Successfully");
                }
            } else {
                setCommonmodal(true)
                setCommonmodaltitle("Information")
                setCommonModalMesg("Something went wrong!");
            }
            setLoading(false)
        }).catch((error: any) => {
            console.log(error, "error");
        });
    }
    const onCancel = () => {
        history.push("/admtemple")
    }


    const callLog = (params: any) => {
        Authservice.saveLogDetails(usertoken, userId, params).then((response: any) => {
            if (response && (response.data)) {
                console.log(response.data)
            }
        }).catch((error: any) => {
            console.log(error, "error");
        });
    }

    const fetchRouteDetails = async () => {
        setTransportDetails("")
        setLoading(true)
        try {
            callLog(`${temName}, ${startCity}`)
            const result: any = await anthropic.messages.create({
                model: "claude-3-5-sonnet-20241022",
                max_tokens: 1024,
                messages: [{
                    role: "user",
                    content: `Provide transportation details to reach ${temName}, ${panchayatName}, ${districtName} from ${startCity}. Include available modes of transport, approximate duration, and rough cost estimates.

                  Respond ONLY with a JSON object with these exact keys:
                  {
                    "transportMode": "string",
                    "duration": "string",
                    "estimatedCost": number,
                    "routes": ["string"]
                  }`
                }],
            });
            console.log(result, "result.content")
            const responseContent = result.content[0].text;
            const parsedResponse = JSON.parse(responseContent);
            console.log(parsedResponse, "parsedResponse")
            setTransportDetails(parsedResponse);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching route details:", error);
            setLoading(false)
        }
    };


    const saveTempleFollowsUp = (mes: any) => {
        Authservice.saveTempleFollowsUp(usertoken, userId, usertoken, templeCode, "TempleOfferPooja", mes).then((res: any) => {
            if (res.data !== "" && res.data !== null) {
                console.log(res.data)
            }
        })
            .catch((error: any) => {
                console.log(error, "error")
                setShowLoading(false)
            })
    }


    const getMessagesByCategory = (e: any) => {
        Authservice.getMessagesByCategory(userId, usertoken, usertoken, e).then((res: any) => {
            console.log(res)
            setCommonModalMesg(res.data)
            setModalOpenFor(e)
            setCommonmodaltitle("Information")
            setCommonmodal(true)
            if (e === "TempleOfferPooja") {
                saveTempleFollowsUp(res.data)
            }
            setShowLoading(false)
        })
            .catch((error: any) => {
                console.log(error, "error")
                setShowLoading(false)
            })
    }

    const handleCloseTransport = () => {
        setStartCity("")
        setTransportDetails("")
        setTransportModal(false)
    }

    const transportModalOpen = () => {
        getMessagesByCategory("TempleTransport")
    }

    const adminSelectionChange = (e: any, label: any) => {
        if (label === "active") {
            if (e.detail.checked) {
                setIsActive(true)
            }
            else {
                setIsActive(false)
            }
        }
        else if (label === "delete") {
            if (e.detail.checked) {
                setIsDeleted(true)
            }
            else {
                setIsDeleted(false)
            }
        }
        else if (label === "vetoed") {
            if (e.detail.checked) {
                setIsVetoed(true)
            }
            else {
                setIsVetoed(false)
            }
        }
        else if (label === "pooja") {
            if (e.detail.checked) {
                setReadyPooja(true)
            }
            else {
                setReadyPooja(false)
            }
        }
    }

    const handleFileSelect = () => {
        fileInputRef.current?.click();
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPoojaList([]);
        const file = event.target.files?.[0];
        if (!file) return;

        if (!file.name.match(/\.(xlsx|xls|csv)$/)) {
            alert("Invalid file type. Please upload an Excel file (.xlsx or .xls).");
            return;
        }

        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = (e) => {
            const binaryStr = e.target?.result as string;
            const workbook = XLSX.read(binaryStr, { type: "binary" });

            // Get first sheet name
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert sheet data to JSON
            let jsonData: any[] = XLSX.utils.sheet_to_json(sheet);

            jsonData = jsonData.map((row) => {
                const formattedRow: any = {};
                Object.keys(row).forEach((key) => {
                    const formattedKey = key.replace(/\s+/g, ""); // Remove spaces
                    formattedRow[formattedKey] = row[key];
                });
                return formattedRow;
            });
            console.log("Excel Data:", jsonData, deityOptions)
            const formattedData = jsonData.map((row, i) => ({
                id: null,
                templeCode: null,
                poojaCode: i + 1,
                timeOfPooja: row.TimeofPooja || null,
                dailyPerformCount: null,
                cost: row.Amount ?? null,
                poojaDescription: row.PoojaDesc || row.poojaDesc || row.PoojaDescription || row.poojaDescription || row.poojaDescription?.trim() || null,
                isDelete: null,
                // deityCode: deityOptions.filter((e: any) => e.label === row.DeityName)[0]?.value,
                prasadamAvailable: row.PrasadamDelivery && ["yes", "y", "Yes"].includes(row.PrasadamDelivery?.trim())
                    ? "1"
                    : row.PrasadamDelivery
                        ? "1"
                        : "0",

                poojaOfferings: null,
                district: null,
                panchayat: null,
                deityName: row.DeityName || row.DeityName?.trim() || null,
                poojaName: row.PoojaName || row.PoojaName?.trim() || null,
                updated: true
            }));

            setPoojaList(formattedData.slice(0, -1));
            console.log("Formatted Data:", formattedData);
        };
    };

    const deletePooja = (id: any) => {

        if (isNaN(id)) {
            Authservice.deleteTemplePooja(usertoken, userId, usertoken, templeCode, id).then((response: any) => {
                if (response.data === true) {
                    setPoojaList(poojaList.filter((e: any) => e.poojaCode !== id))
                }
            }).catch((error: any) => {
                console.log(error, "error");
            });
        } else {
            setPoojaList(poojaList.filter((e: any) => e.poojaCode !== id))
        }
        setShowAlert(false)

    }

    const clickOfferPooja = () => {
        if (!readyPooja) {
            getMessagesByCategory("TempleOfferPooja")

        } else {
            history.push({ pathname: "/offerings", state: { templeCode: templeCode, templeName: temName } })
        }
    }

    const openDeleteAlert = (id: any) => {
        console.log(id)
        setdeletePoojaCode(id)
        setShowAlert(true)
    }


    return (
        <div>
            <IonAlert
                isOpen={showAlert}
                header="Alert"
                message={"Are you sure you want to delete this Pooja?"}
                backdropDismiss={false}
                buttons={[
                    {
                        text: "No",
                        role: "cancel",
                        handler: () => setShowAlert(false),
                    },
                    {
                        text: "Yes",
                        handler: () => deletePooja(deletePoojaCode),
                    },
                ]}
            />
            <IonLoading message="Loading..." spinner={null} isOpen={loading} />
            <Modal show={transportModal} centered>
                <Modal.Header>
                    <h5 className="profileModal-title">Transportation Details<span style={{ fontSize: "18px" }}> (This is the AI generated Information)</span></h5>
                    <div>
                        <IonIcon src={close} className="close_icon" onClick={() => handleCloseTransport()} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ padding: "0px 30px" }}>
                        <IonInput
                            value={startCity}
                            placeholder="Enter Starting City"
                            onIonChange={e => setStartCity(e.detail.value!)}
                        />
                        <IonInput
                            style={{ marginTop: "10px", color: "black" }}
                            value={temName}
                            disabled={true}
                        />
                        <div style={{ marginTop: "20px" }}>
                            <IonButton
                                className='btn_v2'
                                disabled={temName === "" || startCity === ""}
                                onClick={() => fetchRouteDetails()}>
                                <span className='button_padding'>Get Transport Details</span>
                            </IonButton>
                        </div>
                        {transportDetails && (
                            <div style={{ marginTop: "20px" }}>
                                <h3>Transport Modes: {transportDetails.transportMode}</h3>
                                <p>Duration: {transportDetails.duration}</p>
                                <div>
                                    <h4>Estimated Costs:</h4>
                                    {typeof transportDetails.estimatedCost === "object" ? (
                                        <ul>
                                            {Object.entries(transportDetails.estimatedCost).map(([mode, cost]) => (
                                                <li key={mode}>
                                                    {`${mode}: ₹${cost}`}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>Cost: ₹{transportDetails.estimatedCost}</p>
                                    )}
                                </div>
                                <h4>Routes:</h4>
                                <ul>
                                    {transportDetails.routes.map((route: string, index: number) => (
                                        <li key={index}>{route}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
            <IonGrid className='tab_parent_div'>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    {/* Back icon button */}
                    <div onClick={goBack} style={{ marginRight: '10px' }}>
                        <IonIcon icon={arrowBackOutline} size="large" />
                    </div>
                    {/* Title */}
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <IonText style={{ fontSize: '20px', fontWeight: '900' }}>
                            {isedit ? `${temName} [${templeCode}]` : "Add Temple"}
                        </IonText>
                        <IonText style={{ fontSize: '20px', fontWeight: '900' }}>
                            {isedit ? `Last Modified : ${modifiedOn || "N/A"}` : ""}
                        </IonText>
                    </div>
                </div>
                {isUserSide && <IonRow>
                    <IonCol size-lg="3" size-md="6" size-sm="12" size-xs="12">
                        <IonRow >
                            <IonLabel className="temple_details_label">
                                Country <span style={{ color: "red" }}>*</span>
                            </IonLabel>
                        </IonRow>
                        {/* <IonSelect disabled={isUserSide} className="temple_inputs" value={country}>
                            <IonSelectOption value={'IN'}>India</IonSelectOption>
                        </IonSelect> */}
                        <IonInput className="temple_inputs" readonly value={"India"}></IonInput>
                    </IonCol>
                    <IonCol size-lg="3" size-md="6" size-sm="12" size-xs="12">
                        <IonRow>
                            <IonLabel className="temple_details_label">
                                State <span style={{ color: "red" }}>*</span>
                            </IonLabel>
                        </IonRow>
                        {/* <IonSelect disabled={isUserSide} placeholder="Select State" className="temple_inputs" value={state}>
                            <IonSelectOption value={'KL'}>Kerala</IonSelectOption>
                        </IonSelect> */}
                        <IonInput className="temple_inputs" readonly value={"Kerala"}></IonInput>

                    </IonCol>
                    <IonCol size-lg="3" size-md="6" size-sm="12" size-xs="12">
                        <IonRow>
                            <IonLabel className="temple_details_label">
                                District <span style={{ color: "red" }}>*</span>
                            </IonLabel>
                        </IonRow>
                        {/* <IonSelect disabled={isUserSide} placeholder="Select District" className="temple_inputs" value={district} onIonChange={(e) => handleInputChange("district", e)}>
                            {districtOptions.map((e: any) => (
                                <IonSelectOption value={e.districtCode}>{e.districtName}</IonSelectOption>
                            ))}
                        </IonSelect> */}
                        <IonInput className="temple_inputs" readonly value={districtOptions?.find((e: any) => e.districtCode == district)?.districtName || ""}></IonInput>


                    </IonCol>
                    <IonCol size-lg="3" size-md="6" size-sm="12" size-xs="12">
                        <IonRow>
                            <IonLabel className="temple_details_label">
                                Panchayat <span style={{ color: "red" }}>*</span>
                            </IonLabel>
                        </IonRow>
                        {/* <IonSelect disabled={isUserSide} placeholder="Select Panchayat" className="temple_inputs" value={panchayat} onIonChange={(e) => handleInputChange("pan", e)}>
                            {panchayOptions.map((e: any) => (
                                <IonSelectOption value={e.panchayatCode}>{e.panchayatName}</IonSelectOption>
                            ))}
                        </IonSelect> */}
                        <IonInput className="temple_inputs" readonly value={panchayOptions.find((e: any) => e.panchayatCode == panchayat)?.panchayatName || ""}></IonInput>
                    </IonCol>
                </IonRow>
                }
                {menuItems.map((section: any, index: any) => {
                    var flag = true
                    var serviceFlag = true
                    var summaryFlag = true
                    if (index === 0 && isUserSide) {
                        flag = false
                    }
                    if (index === 13 && !isUserSide) {
                        serviceFlag = false
                    }
                    if (index === 1 && aerial !== "" && isUserSide) {
                        summaryFlag = false
                    }
                    if (flag && serviceFlag && summaryFlag)
                        return (
                            <div key={index} className={section.isOpen === true ? 'temple_tab_container temple_active_tab_container' : 'temple_tab_container'}>

                                <div onClick={() => openContent(index)} className={section.isOpen === true ? 'temple_tab label_icon active_tab_container' : 'label_icon temple_tab'}>
                                    <IonIcon src={chevronDownOutline} />
                                    <IonLabel>{section.name}</IonLabel>
                                </div>

                                {section.isOpen &&
                                    <div className='tab_content_container'>
                                        {index === 0 ? (
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Temple Name <span style={{ color: "red" }}>*</span>
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonInput disabled={isUserSide} placeholder="Enter Temple Name" className="temple_inputs"
                                                            value={temName} onIonChange={(e) => setTemName(e.detail.value!)} />
                                                        {formErrors.templeName && <span className="alert_input_bottom ">{formErrors.templeName}</span>}
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                                        <IonLabel className="temple_details_label">Select Admin</IonLabel>
                                                        <Select
                                                            className="select_user_admin"
                                                            options={adminList}
                                                            // value={matchedDeity}
                                                            placeholder="Select a Admin"
                                                            value={selectedAdmin}
                                                            isClearable
                                                            onChange={(e) => setSelectedAdmin(e)}
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                            }}
                                                        />
                                                    </IonCol>
                                                    {serviceChrgDetails.map((item: any, i: any) => (
                                                        <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                                            <IonLabel>
                                                                {item.serviceDescription}
                                                            </IonLabel>
                                                            <IonInput onIonChange={(e: any) => serviceChrgUpdate(item, e.detail.value)} value={getValueofcharge(item)} type="number" />
                                                        </IonCol>
                                                    ))}
                                                </IonRow>
                                                <IonRow className="admin_controller_menu_container">
                                                    <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                                        <IonCheckbox style={{ zIndex: 0 }} className="multiple_checkbox_style" checked={isActive} onIonChange={(e) => adminSelectionChange(e, "active")} />
                                                        <IonLabel>isActive</IonLabel>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                                        <IonCheckbox className="multiple_checkbox_style" checked={isDeleted} onIonChange={(e) => adminSelectionChange(e, "delete")} />
                                                        <IonLabel>isDelete</IonLabel>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                                        <IonCheckbox className="multiple_checkbox_style" checked={isVetoed} onIonChange={(e) => adminSelectionChange(e, "vetoed")} />
                                                        <IonLabel>isVetoed</IonLabel>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                                        <IonCheckbox className="multiple_checkbox_style" disabled={!selectedAdmin} checked={readyPooja} onIonChange={(e) => adminSelectionChange(e, "pooja")} />
                                                        <IonLabel>Ready for Pooja</IonLabel>
                                                    </IonCol>
                                                    {!isVetoed || vetoedBy !== "" &&
                                                        <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                                            <IonLabel>Vetoed By :</IonLabel>
                                                            <IonText>{vetoedBy}</IonText>
                                                        </IonCol>
                                                    }
                                                    {!isVetoed || vetoedOn !== "" &&
                                                        <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                                            <IonLabel>Vetoed On :</IonLabel>
                                                            <IonText>{vetoedOn}</IonText>
                                                        </IonCol>
                                                    }
                                                    {modifiedBy !== "" &&
                                                        <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                                            <IonLabel>Modified By :</IonLabel>
                                                            <IonText>{modifiedBy}</IonText>
                                                        </IonCol>
                                                    }
                                                    {modifiedOn !== "" &&
                                                        <IonCol size-lg="3" size-md="3" size-xs="12" size-sm="12">
                                                            <IonLabel>Modified On :</IonLabel>
                                                            <IonText>{modifiedOn}</IonText>
                                                        </IonCol>
                                                    }
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                                        <IonRow >
                                                            <IonLabel className="temple_details_label">
                                                                Country <span style={{ color: "red" }}>*</span>
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect disabled={isUserSide} className="temple_inputs" value={country}>
                                                            <IonSelectOption value={'IN'}>India</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                State <span style={{ color: "red" }}>*</span>
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect disabled={isUserSide} placeholder="Select State" className="temple_inputs" value={state}>
                                                            <IonSelectOption value={'KL'}>Kerala</IonSelectOption>
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                District <span style={{ color: "red" }}>*</span>
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect disabled={isUserSide} placeholder="Select District" className="temple_inputs" value={district} onIonChange={(e) => handleInputChange("district", e)}>
                                                            {districtOptions.map((e: any) => (
                                                                <IonSelectOption value={e.districtCode}>{e.districtName}</IonSelectOption>
                                                            ))}
                                                        </IonSelect>
                                                    </IonCol>
                                                    <IonCol size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                                        <IonRow>
                                                            <IonLabel className="temple_details_label">
                                                                Panchayat <span style={{ color: "red" }}>*</span>
                                                            </IonLabel>
                                                        </IonRow>
                                                        <IonSelect disabled={isUserSide} placeholder="Select Panchayat" className="temple_inputs" value={panchayat} onIonChange={(e) => handleInputChange("pan", e)}>
                                                            {panchayOptions.map((e: any) => (
                                                                <IonSelectOption value={e.panchayatCode}>{e.panchayatName}</IonSelectOption>
                                                            ))}
                                                        </IonSelect>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        ) : index === 1 ? (
                                            <div>
                                                <IonLabel className="input-label-editTem">Summary</IonLabel>
                                                <textarea
                                                    className={isUserSide ? "disabled_textarea summary_textarea" : "undisabled_textarea summary_textarea"}
                                                    value={summary}
                                                    onChange={(e) => SetSummary(e.target.value)}
                                                    placeholder="Enter Summary"
                                                    disabled={isUserSide}
                                                />
                                            </div>
                                        ) : index === 2 ? (
                                            <div>
                                                <IonCol size="12" style={isUserSide ? { display: "flex", alignItem: "start" } : { display: "block" }}>

                                                    <IonRow style={{ width: "100%" }}>
                                                        <IonCol size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                                            <IonRow>
                                                                <IonLabel className="temple_details_label">
                                                                    About & history <span style={{ color: "red" }}>*</span>
                                                                </IonLabel>
                                                            </IonRow>
                                                            <IonTextarea autoGrow={true} readonly={isUserSide} placeholder="Enter About & history" className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                                value={aerial} onIonChange={(e) => setAerial(e.target.value)} />
                                                            {/* {formErrors.abtAndHis && <span className="alert_input_bottom ">{formErrors.abtAndHis}</span>} */}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCol>
                                            </div>
                                        ) : index === 3 ? (
                                            <div>
                                                <>
                                                    <IonLabel className="input-label-editTem">Main Deity</IonLabel>
                                                    <IonInput
                                                        value={maindeity}
                                                        onIonChange={(e) => setmaindeity(e.detail.value!)}
                                                        placeholder="Enter Time of Worship"
                                                        readonly={isUserSide}
                                                    />
                                                </>
                                                <>
                                                    <IonLabel className="input-label-editTem">Other Deities</IonLabel>
                                                    <IonTextarea
                                                        value={otherDeities}
                                                        onIonChange={(e) => setotherDeities(e.detail.value!)}
                                                        placeholder="Enter Time of Worship"
                                                        readonly={isUserSide}
                                                        autoGrow={true}
                                                    />
                                                </>
                                            </div>
                                        ) : index === 4 ? (
                                            <div>
                                                <IonLabel className="input-label-editTem">Location</IonLabel>
                                                <IonTextarea
                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                    value={locationInfo}
                                                    autoGrow={true}
                                                    onIonChange={(e: any) => {
                                                        setLocationInfo(e.target.value);
                                                        getCoordinatesFromAddress(e.target.value!);
                                                    }}
                                                    placeholder="Enter Latitude and Longitude"
                                                    readonly={isUserSide}
                                                />
                                                <IonGrid className="grid">
                                                    <IonRow>
                                                        <IonCol size-lg="12" size-md="12" size-xs="12" size-sm="12">
                                                            <IonButton
                                                                className="btn_v2"
                                                                onClick={() => transportModalOpen()}
                                                            >
                                                                <span className="button_padding">Transpotation Details</span>
                                                            </IonButton>
                                                            {/* {isUserSide && */}
                                                            <IonButton
                                                                className="btn_v2"
                                                                onClick={openMap}
                                                            >
                                                                <span className="button_padding">  Share Map Link</span>
                                                                <IonIcon src={shareSocialSharp} className='share_icon' />
                                                            </IonButton>
                                                            {/* } */}
                                                            {/* <div className='parent'>
                                                                {window.google == undefined ? (
                                                                    <LoadScript googleMapsApiKey={Authservice.mapKey()}>
                                                                        <GoogleMap
                                                                            mapContainerClassName="containerStyle"
                                                                            center={center}
                                                                            zoom={15}
                                                                            options={mapOption}
                                                                        >
                                                                            <MarkerF position={center} />
                                                                        </GoogleMap>
                                                                    </LoadScript>
                                                                ) : (
                                                                    <GoogleMap
                                                                        mapContainerClassName="containerStyle"
                                                                        center={center}
                                                                        zoom={15}
                                                                        options={mapOption}
                                                                    >
                                                                        <MarkerF position={center} />
                                                                    </GoogleMap>
                                                                )}
                                                                <div className='child'>
                                                                    {!isUserSide && (
                                                                        <IonButton
                                                                            className='map_share'
                                                                            onClick={openMap} // function to open map link
                                                                        >
                                                                            Share Map Link
                                                                            <IonIcon src={shareSocialSharp} className='share_icon' />
                                                                        </IonButton>
                                                                    )}
                                                                </div>
                                                            </div> */}
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                            </div>
                                        ) : index === 5 ? (
                                            <div>
                                                <IonLabel className="input-label-editTem">Facilities</IonLabel><br />
                                                <textarea
                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                    value={facilities}
                                                    onChange={(e) => setFacilities(e.target.value)}
                                                    placeholder="Enter Facilities"
                                                    readOnly={isUserSide}
                                                />
                                                <br />
                                                <IonLabel className="input-label-editTem">Dress Code</IonLabel>
                                                <textarea
                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                    value={dressCode}
                                                    onChange={(e) => setDressCode(e.target.value)}
                                                    placeholder="Enter Dress Code"
                                                    readOnly={isUserSide}
                                                />
                                            </div>
                                        ) : index === 6 ? (
                                            <div>
                                                <IonLabel className="input-label-editTem">Time of Worship</IonLabel>
                                                <textarea
                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                    value={timeOfWorship}
                                                    onChange={(e) => setTimeOfWorship(e.target.value)}
                                                    placeholder="Enter Time of Worship"
                                                    readOnly={isUserSide}
                                                />
                                            </div>
                                        ) : index === 7 ? (
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "end", alignItems: "center", paddingBottom: "10px" }}>
                                                    {/* <IonLabel className="input-label-editTem">Pooja Offerings {showModal ? "- Main List" : ""}</IonLabel> */}
                                                    {!isUserSide ? <div>
                                                        {showModal && < IonButton className="admin_add_btn" onClick={() => AddpoojaModal()} color="primary">
                                                            <span className="button_padding_v2">
                                                                Add
                                                            </span>
                                                        </IonButton>
                                                        }
                                                        {showModal && < IonButton className="admin_add_btn" onClick={() => handleFileSelect()} color="primary">
                                                            <span className="button_padding_v2">
                                                                Import
                                                            </span>
                                                        </IonButton>
                                                        }
                                                        < IonButton className="admin_add_btn" onClick={() => openPoojaModel()} color="primary">
                                                            {showModal ? <span className="button_padding_v2">
                                                                Cancel
                                                            </span> : <span className="button_padding_v2">
                                                                Add/Edit Pooja
                                                            </span>}
                                                        </IonButton></div> :
                                                        < IonButton className="admin_add_btn" onClick={clickOfferPooja} color="primary" >
                                                            <span className="button_padding_v2">
                                                                Offer Pooja
                                                            </span>
                                                        </IonButton>

                                                    }
                                                    <input type="file" accept=".xlsx, .xls, .csv" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileUpload} />

                                                </div>
                                                <div>
                                                    {showModal ? <>
                                                        <CommonDataTable
                                                            columns={poojaColumn}
                                                            data={poojaList}
                                                            loading={showLoading}
                                                            noDataMessage="Sorry, No data available"
                                                            onRowClick={handleSelectedRowsChange}
                                                            selectableRowSelected={(row: any) => selectedPoojaRows.includes(row.poojaCode)} />
                                                    </> : <>
                                                        <textarea
                                                            className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                            value={poojaoffer}
                                                            onChange={(e) => setPoojaOffer(e.target.value)}
                                                            placeholder="Enter Pooja Offerings"
                                                            readOnly={isUserSide}
                                                        />
                                                    </>}</div>

                                            </div>
                                        ) : index === 8 ? (
                                            <div>
                                                <IonLabel className="input-label-editTem">Festivals</IonLabel>
                                                <textarea
                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                    value={festival}
                                                    onChange={(e) => setFestival(e.target.value!)}
                                                    placeholder="Enter Festivals"
                                                    readOnly={isUserSide}
                                                />
                                            </div>
                                        ) : index === 9 ? (
                                            <div>
                                                <IonLabel className="input-label-editTem">Structure</IonLabel>
                                                <textarea
                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                    value={structure}
                                                    onChange={(e) => setStructure(e.target.value)}
                                                    placeholder="Enter Structure"
                                                    readOnly={isUserSide}
                                                />
                                                <IonLabel className="input-label-editTem">Carvings</IonLabel>
                                                <textarea
                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                    value={carvings}
                                                    onChange={(e) => setCarvings(e.target.value)}
                                                    placeholder="Enter Carvings"
                                                    readOnly={isUserSide}
                                                />
                                            </div>
                                        ) : index === 10 ? (
                                            <div>
                                                <IonLabel className="input-label-editTem">Review</IonLabel>
                                                <textarea
                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                    value={review}
                                                    onChange={(e) => setReview(e.target.value)}
                                                    placeholder="Enter Review"
                                                    disabled={isUserSide}
                                                />
                                            </div>
                                        ) : index === 11 ? (
                                            <div>
                                                <IonLabel className="input-label-editTem">Administration</IonLabel>
                                                <textarea
                                                    className={isUserSide ? "disabled_textarea" : "undisabled_textarea"}
                                                    value={Administration}
                                                    onChange={(e) => setAdministration(e.target.value)}
                                                    placeholder="Enter Administration"
                                                    disabled={isUserSide}
                                                />
                                            </div>
                                        ) : index === 12 ? (
                                            <div>
                                                {!isUserSide && < IonRow >
                                                    <input
                                                        style={{ display: "none" }}
                                                        ref={imageinputref}
                                                        type="file"
                                                        name="photo"
                                                        onChange={(e) => onchangeImage(e)}
                                                        multiple={true}
                                                    />
                                                    <IonCol size="2">
                                                        <IonButton onClick={() => imageinputref.current?.click()} className="admin_img_btn image_content">
                                                            <span>Choose File</span>
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>}

                                                <IonRow>
                                                    <IonCol size="12">
                                                        {!isUserSide && <IonRow style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                                                            <p style={{ color: "red", fontSize: "10px" }}>
                                                                * The first image will be displayed as Temple Image
                                                            </p>
                                                        </IonRow>}
                                                        <IonRow className="ion-justify-content-center">
                                                            {image ? image.map((item: any, i: number) => (
                                                                <IonCol sizeLg="4" size="4" key={i}>
                                                                    <div style={{ display: "flex", justifyContent: "right" }}>
                                                                        <IonIcon
                                                                            src={closeCircle}
                                                                            tabIndex={0}
                                                                            size="small"
                                                                            style={{ cursor: "pointer", color: '#576F9F' }}
                                                                            onClick={() => removeImage(i)}
                                                                        />
                                                                    </div>
                                                                    <IonImg
                                                                        src={item.image}
                                                                        tabIndex={1}
                                                                        className="img"
                                                                        onClick={() => { /* Open modal to view larger image */ }}
                                                                    />
                                                                </IonCol>
                                                            )) : "No Data Available"}
                                                        </IonRow>
                                                    </IonCol>
                                                </IonRow>

                                                {/* Video Section */}
                                                {/* <IonRow style={{ marginTop: '1%' }}>
                                                <IonCol size="11" size-xs="10">
                                                    <IonLabel className="temple_facilites_head_text">
                                                        Video Link
                                                    </IonLabel>
                                                </IonCol>
                                                <IonCol size="1" size-xs="2" style={{ display: "flex", alignItems: "center" }}>
                                                    <IonIcon
                                                        src={addCircle}
                                                        className="video_add_icon"
                                                        onClick={() => addVideoInput(false)}
                                                    />
                                                </IonCol>
                                            </IonRow>

                                            {videolink.map((item: any, i: number) => (
                                                <IonRow key={i}>
                                                    <IonCol size="11" size-xs="10">
                                                        <IonRow>
                                                            <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                <IonLabel className="temple_details_label">Video Name</IonLabel>
                                                                <IonTextarea
                                                                    className="temple_textArea temple_inputs"
                                                                    value={item.videoName}
                                                                    onIonChange={(e) => handleVideoNameChange(e, i, false)}
                                                                    ref={videoInputRef}
                                                                    autofocus={true}
                                                                />
                                                            </IonCol>
                                                            <IonCol size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                                                <IonLabel className="temple_details_label">Video Link</IonLabel>
                                                                <IonTextarea
                                                                    className="temple_textArea temple_inputs"
                                                                    value={item.videoLink}
                                                                    onIonChange={(e) => handleVideoLinkChange(e, i, false)}
                                                                />
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonCol>
                                                    <IonCol size="1" size-xs="2" style={{ display: "flex", alignItems: "center" }}>
                                                        <IonIcon
                                                            src={removeCircle}
                                                            onClick={() => removeVideo(i)}
                                                            className="video_add_icon"
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                            ))} */}
                                            </div>
                                        ) : index === 13 ? (
                                            <div>
                                                <>
                                                    {isUserSide ? (
                                                        <p>{ServiceCharge ? ServiceCharge : "No Data Available"}</p> // Display as text
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            </div>
                                        ) :
                                            null}
                                    </div>
                                }
                            </div>
                        )
                })
                }
            </IonGrid >
            {
                isUserSide ? null : (
                    <IonRow style={{ justifyContent: "flex-end" }}>
                        <IonButton onClick={onsubmit} className="admin_add_btn">
                            <span className="button_padding_v2">
                                Submit
                            </span>
                        </IonButton>
                        <IonButton onClick={() => onCancel()} className="admin_cencel_btn" fill="outline">
                            <span className="button_padding_v2">
                                Cancel
                            </span>
                        </IonButton>
                    </IonRow>
                )
            }
            < Modal show={modalopen} className="off_info_modal" centered size={"xl"} >
                <Modal.Header>
                    <IonGrid>
                        <IonRow className="off_info_modal_header">
                            <IonCol size-lg="11" size-md="11" size-xs="11" size-sm="11">
                                <IonText className="info_modal_offname">Information</IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>

                        {/* Hide Table when Adding Review */}
                        {!showReviewInput ? (
                            <>
                                <CommonDataTable
                                    columns={isUserSide ? modalColumn : modalColumnAdmin}
                                    data={modalData}
                                    loading={showLoading}
                                    type="reviewTable"
                                    noDataMessage="Sorry, No data available"
                                />

                                {/* Add Review & Cancel Buttons in Same Line */}
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "15px", gap: "10px" }}>
                                    <IonButton className="btn_v2 admin_add_btn" onClick={() => setShowReviewInput(true)}>
                                        <span className="button_padding_v2">
                                            Add Review
                                        </span>
                                    </IonButton>
                                    <IonButton className="btn_v2 admin_cencel_btn" fill="outline" onClick={() => setModalOpen(false)}>
                                        <span className="button_padding_v2">
                                            Cancel
                                        </span>
                                    </IonButton>
                                </div>
                            </>
                        ) : (
                            /* Review Input Section */
                            <div style={{ width: "100%", textAlign: "center", marginTop: "15px" }}>
                                <textarea
                                    placeholder="Write your review here..."
                                    value={reviewText}
                                    onChange={(e) => setReviewText(e.target.value)}
                                    style={{
                                        width: "80%",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        border: "1px solid #ccc",
                                        fontSize: "14px",
                                    }}
                                ></textarea>

                                {/* Submit & Back Buttons in Same Line */}
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "10px", gap: "10px" }}>
                                    <IonButton className="btn_v2 admin_add_btn" onClick={handleSubmitReview}>
                                        <span className="button_padding">  Submit Review </span>
                                    </IonButton>
                                    <IonButton className="btn_v2 admin_cencel_btn" fill="outline" onClick={() => setShowReviewInput(false)}>
                                        <span className="button_padding">  Back</span>
                                    </IonButton>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal >
            <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
            <Modal show={bookDetailsModal} className="admin_modal">
                <Modal.Body className="modal_container" style={{ width: "100%" }}>
                    <div className="donation-modal-header admin-user-modal-header">
                        <h5 className="donation-modal-title">Pooja Details</h5>
                        <div>
                            <IonIcon
                                src={close}
                                className="donation-modal-close-icon close_icon"
                                onClick={() => handleDeselectRow()}
                            />
                        </div>
                    </div>
                    <IonRow>
                        <IonCol size="6">
                            <IonLabel className="temple_details_label">Pooja Name</IonLabel>
                            <IonInput
                                className="temple_textArea temple_inputs"
                                value={editPoojaItem.poojaName}
                                onIonChange={(e) => handlePoojaChange(e, "poojaName")}
                                placeholder="Enter Pooja Name"
                            />
                            {formErrors.name && (
                                <span className="alert_input_bottom">{formErrors.name}</span>
                            )}
                        </IonCol>
                        <IonCol size="6">
                            <IonLabel className="temple_details_label">Time of Pooja</IonLabel>
                            <IonInput
                                className="temple_textArea temple_inputs"
                                value={editPoojaItem.timeOfPooja}
                                onIonChange={(e) => handlePoojaChange(e, "timeOfPooja")}
                                placeholder="Enter time of Pooja"
                            />
                            {formErrors.time && (
                                <span className="alert_input_bottom">{formErrors.time}</span>
                            )}
                        </IonCol>
                        <IonCol size="6">
                            <IonLabel className="temple_details_label">Cost</IonLabel>
                            <IonInput
                                className="temple_textArea temple_inputs"
                                value={editPoojaItem.cost}
                                onIonChange={(e) => handlePoojaChange(e, "cost")}
                                placeholder="Enter cost"
                            />
                            {formErrors.cost && (
                                <span className="alert_input_bottom">{formErrors.cost}</span>
                            )}
                        </IonCol>
                        <IonCol size="6">
                            <IonLabel className="temple_details_label">Pooja Description</IonLabel>
                            <IonInput
                                className="temple_textArea temple_inputs"
                                value={editPoojaItem.poojaDescription}
                                onIonChange={(e) => handlePoojaChange(e, "poojaDescription")}
                                placeholder="Enter pooja description"
                            />
                            {formErrors.des && (
                                <span className="alert_input_bottom">{formErrors.des}</span>
                            )}
                        </IonCol>
                        <IonCol size="6">
                            <IonLabel className="temple_details_label">Select Deity</IonLabel>
                            <Select
                                className="temple_inputs"
                                options={deityOptions}
                                value={matchedDeity}
                                placeholder="Select a Deity"
                                isClearable
                                onChange={(e) => handlePoojaChange(e, "deityCode")}
                            />
                            {formErrors.deity && (
                                <span className="alert_input_bottom">{formErrors.deity}</span>
                            )}
                        </IonCol>
                        <IonCol size="6">
                            <IonLabel className="temple_details_label">Prasadham Available</IonLabel>
                            <Select
                                className="temple_inputs"
                                options={prasadhamOptions}
                                value={matchedPrasadham}
                                isClearable
                                onChange={(e) => handlePoojaChange(e, "prasadamAvailable")}
                            />
                            {formErrors.prasadham && (
                                <span className="alert_input_bottom">{formErrors.prasadham}</span>
                            )}
                        </IonCol>
                        <IonCol size="12">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    gap: "10px",
                                    marginTop: "20px",
                                }}
                            >
                                <IonButton
                                    className="admin_add_btn"
                                    onClick={() => handleDeselectRow()}
                                    color="primary"
                                >
                                    <span className="button_padding_v2">Cancel</span>
                                </IonButton>
                                <IonButton
                                    className="admin_add_btn"
                                    onClick={() => savepoojadetails()}
                                    color="primary"
                                >
                                    <span className="button_padding_v2">Save</span>
                                </IonButton>
                            </div>
                        </IonCol>
                    </IonRow>
                </Modal.Body>
            </Modal>

        </div >
    );
}

export default TempleComponent;
