import React from "react";
import axios from "axios";
import { AnyTxtRecord } from "dns";
import { hasData } from "jquery";
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { stat } from "fs";



// const API_URL = "https://groupnpay.com/vedagram/";
//const API_URL = "http://uat.atmagram.com/";
//const API_URL = "https://www.atmagram.com/"; 

//const API_URL = "https://groupnpay.com/vedagram/";
//const API_URL = "https://demo.infocareerindia.com/vedagram/";
// const API_URL = "http://192.168.169.126:8090/"
// const API_URL = "http://192.168.2.155:8090/"
// const API_URL = "http://192.168.3.155:8090/"
// const API_URL = "https://www.atmagram.com/testing"
const API_URL = "https://www.atmagram.com"



const share_URL = API_URL;

const googleMapsApiKey = "AIzaSyCU3S7JWcJQntMHoT3dMltNY4JGpZqnfWg";

const formatPhone = (phoneNo: any) => {
  const parsedNumber = parsePhoneNumber(phoneNo);
  const formatted = parsedNumber ? parsedNumber.formatInternational() : phoneNo
  return formatted;
};
const getApiUrl = () => {
  return share_URL;
}
const mapKey = () => {
  return googleMapsApiKey;
}

const myProfile = (user_id: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("userId", user_id)
  data.append("sessionId", usertoken)

  return api
    .post("user/myProfile", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const Profileupdate = (
  aadhar: any,
  address: any,
  pincode: any,
  area: any,
  city: any,
  country: any,
  email: any,
  firstname: any,
  lastname: any,
  phone: any,
  state: any,
  usertoken: any,
  userId: any,
  district: any,
  panchayat: any,
  id: any,
  panLimt: any,
  districtLimit: any
) => {

  const panchayatlim = panLimt.length > 0 ? panLimt.map((item: any) => item.value).join(',') : "";
  const districtlim = districtLimit.length > 0 ? districtLimit.map((item: any) => item.value).join(',') : "";
  const logindata: any = {
    aadharNumber: aadhar,
    address: address + ',' + pincode,
    area: area,
    city: city,
    // country: country,
    country: "IN",
    state: "KL",
    email: email,
    firstName: firstname,
    lastName: lastname,
    mobileNumber: phone,
    productType: null,
    // state: state,
    vendorName: null,
    district: district,
    panchayat: panchayat,
    id: id,
    panchayatLimit: panchayatlim,
    districtLimit: districtlim,
    userId: userId
  };

  console.log(logindata)

  const data = new FormData()
  data.append("userId", userId)
  data.append("sessionId", usertoken)
  data.append("registerModel", JSON.stringify(logindata))


  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("user/update", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const ChangePassword = (
  newpassword: any,
  oldpassword: any,
  confirmpassword: any,
  usertoken: any
) => {
  const logindata = {
    password: newpassword,
    oldPassword: oldpassword,
    confirmPassword: confirmpassword,
  };

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("user/changePassword", logindata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
const ChangeNumber = (newNumber: any, usertoken: any) => {
  const numberBody: any = {
    "mobileNumber": newNumber
  };
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  console.log(numberBody, "Number")
  return api
    .post("user/changeMobileNumber", numberBody)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};


const Showallusers = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/showAllUsers")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


const updateDeity = (usertoken: any, userID: any, isedit: any, description: any, deityid: any, name: any, images: any, remark: any, code: any) => {

  var url = "";
  const obj = {
    id: deityid ? deityid : "",
    deityCode: code ? code : "", deityName: name
    , significance: description
    , remarks: remark
  }
  var formdata: any = new FormData();
  if (isedit) {
    url = "updateDeity"
  } else {
    url = "addDeity"
  }
  formdata.append("deity", JSON.stringify(obj));
  if (images.length === 0) {
    formdata.append("deityImage", []);
  } else {
    const blobObj = new File([images], 'deityImage', { type: images.type });
    console.log(blobObj)
    formdata.append("deityImages", blobObj);
  }
  formdata.append("userId", userID);
  formdata.append("sessionId", usertoken);

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  return api
    .post("adm/" + url, formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Viewtemple = (usertoken: any, tempId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("tempadm/getAllTemples?tempAdmId=" + tempId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Edittemple = (id: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .get("tempadm/viewTemple?templeId=" + id)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error;
    });
};

const updateTemple = (usertoken: any, templesdto: any, facilities: any, Offerings: any, festival: any, images: any, poojarow: any, templeVideoLinksDto: any, deleteImg: any, festivalId: any, offeringId: any, videoLinkId: any, tempId: any, liveVideoLinkid: any, poojaListId: any) => {
  var url = "";
  const formdata: any = new FormData();

  console.log(deleteImg, 'img')
  console.log(festivalId, "fesId")
  console.log(offeringId, "OffId")
  console.log(videoLinkId, "VideoId")
  console.log(poojaListId, "list Id")
  var obj = {
    "templesDto": templesdto,
    "facilitiesDto": facilities,
    "festivalsDto": festival,
    "poojaOfferingsDto": poojarow,
    "offeringsAndSignificanceDto": Offerings,
    "templeVideoLinksDto": templeVideoLinksDto,
    "templeImgsUrl": deleteImg,
    "festivalId": festivalId,
    "offeringAndSigId": offeringId,
    "videoLinkId": videoLinkId,
    "templeAdminId": tempId,
    "liveVideoLinkId": liveVideoLinkid,
    "poojaOfferingsId": poojaListId
  }
  console.log(images)
  // formdata.append('imgList',images)
  if (images.length > 0) {
    for (let i = 0; i < images.length; i++) {
      console.log(images[i])
      formdata.append('imgList', images[i])
    }
  } else {
    formdata.append('imgList', [])
  }

  const json = JSON.stringify(obj);
  console.log(json)
  const blobObj = new Blob([json], {
    type: 'application/json'
  });

  formdata.append('createTempleDto', blobObj)

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  return api
    .post("/tempadm/updateTemple", formdata)
    .then((response: any) => {
      console.log("redssss", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}


const createTemple = (usertoken: any, facilitiesDto: any, offeringsAndSignificanceDto: any, festivalsDto: any, templesDto: any, images: any, poojarow: any, videolink: any, lat: any, long: any, tempId: any) => {

  const formdata: any = new FormData();

  console.log(facilitiesDto)
  var obj = {
    "templesDto": templesDto,
    "facilitiesDto": facilitiesDto,
    "festivalsDto": festivalsDto,
    "poojaOfferingsDto": poojarow,
    "offeringsAndSignificanceDto": offeringsAndSignificanceDto,
    "templeVideoLinksDto": videolink,
    "tempLat": lat,
    "tempLong": long,
    "templeAdminId": tempId
  }
  console.log(images)
  // formdata.append('imgList',images)
  for (let i = 0; i < images.length; i++) {
    console.log(images[i])
    formdata.append('imgList', images[i])
  }
  const json = JSON.stringify(obj);
  console.log(json)
  const blobObj = new Blob([json], {
    type: 'application/json'
  });

  formdata.append('createTempleDto', blobObj)
  // formdata.append('createTempleDto',JSON.stringify(obj))


  console.log(formdata)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  return api
    .post("/tempadm/createTemple", formdata)
    .then((response: any) => {
      console.log("redssss", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const ShowdeitTemp = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("tempadm/showAllDeity")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Poojaofferings = (id: any, tid: any) => {
  console.log("jgcd", id, "jkhjhj", tid)
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/u/showPoojaOffering?deityId=" + id + "&tempId=" + tid)
    .then((response: any) => {
      console.log("res=>>>>", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const AddToCartInit = (details: any, usertoken: any, guestTkn: any) => {
  var api;

  if (usertoken == null) {
    api = axios.create({
      baseURL: API_URL,

    });
  } else {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });
  }

  return api
    .post("/u/addToCartInit?guestToken=" + guestTkn, details)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const Payinitoffering = (details: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payInitPoojaOfferings", details)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const Payresoffering = (details: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payResPoojaOfferings", details)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const payInit = (usertoken: any, details: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payinitmultipoojaofferings")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const payOfferingRes = (usertoken: any, details: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payresmultipoojaofferings", details)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


const MyOrder = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/u/myOrders")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Addcart = (usertoken: any, userId: any) => {
  var api;
  const data: any = new FormData()
  data.append("sessionId", usertoken)
  data.append("userId", userId)
  if (usertoken != null && usertoken != undefined && usertoken != "") {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });
  } else {
    api = axios.create({
      baseURL: API_URL,
    });

  }
  return api
    .post("/u/getConfirmedBookingOrders")
    .then((response: any) => {
      console.log("res=>>>>", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const OrderTemp = (usertoken: any, id: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("tempadm/showAllOrders?tempAdmId=" + id)
    .then((response: any) => {
      console.log("res==>", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ShowAllOrder = (usertoken: any) => {
  console.log("kjg", usertoken)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/showAllOrders")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const removeCart = (usertoken: any, id: any) => {
  var api;

  if (usertoken != null && usertoken != undefined && usertoken != "") {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });

  } else {
    api = axios.create({
      baseURL: API_URL,

    });

  }
  return api
    .post("/u/removeFromCart?vedaCartId=" + id)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Materialadm = (usertoken: any) => {
  console.log("kjg", usertoken)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/adm/showAllPoojaMaterial")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Viewmaterialadm = (usertoken: any, materialId: any) => {
  console.log("kjgkhk", usertoken)
  console.log("sjdf", materialId)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/adm/viewPoojaMaterial?materialId=" + materialId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialList = (usertoken: any, venId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/ven/showAllPoojaMaterial?vendorId=" + venId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const addMaterial = (usertoken: any, isedit: any, brand: any, price: any, name: any, images: any, pUnit: any, psize: any, stock: any, mid: any, quantity: any, venId: any) => {
  var url = "";
  var formdata: any = new FormData();
  console.log(name, brand, price, "un", pUnit, "si", psize, "i", images, "s", stock, "mid", mid)
  if (isedit) {
    url = "updatePoojaMaterial"
    formdata.append("id", mid);
    formdata.append("productName", name);
    formdata.append("brandName", brand);
    formdata.append("price", price);
    formdata.append("materialStock", stock);
    formdata.append("quantity", quantity);
    formdata.append("packageSize", psize);
    formdata.append("packageUnit", pUnit);
    formdata.append("vendorId", venId);
    if (images.length === 0) {
      formdata.append("materialImage", []);
    } else {
      const blobObj = new File([images], 'materialImage', { type: images.type });
      console.log(blobObj)
      formdata.append("materialImage", blobObj);
    }

  } else {
    url = "addPoojaMaterial"
    formdata.append("productName", name);
    formdata.append("brandName", brand);
    formdata.append("price", price);
    formdata.append("materialStock", stock);
    formdata.append("quantity", quantity);
    formdata.append("materialImage", images);
    formdata.append("packageSize", psize);
    formdata.append("packageUnit", pUnit);
    formdata.append("vendorId", venId);
  }
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  return api
    .post("ven/" + url, formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ShowAllPoojaMaterial = (id: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .get("u/showAllPoojaMaterial?templeId=" + id)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Grammerceadm = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/adm/showAllGrammerce")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Viewgrammerceadm = (usertoken: any, grammerceId: any) => {
  console.log("kjgkhk", usertoken)
  console.log("sjdf", grammerceId)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/adm/viewGrammerce?grammerceId=" + grammerceId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const updategrammerce = (usertoken: any, isedit: any, name: any, images: any, productname: any, price: any, significance: any, stock: any, gid: any, delleadtime: any, country: any, state: any, district: any) => {
  console.log(images, "qqqqqqqq1qq")

  var url = "";
  var formdata: any = new FormData();
  if (isedit) {
    url = "updateGrammerce"
    formdata.append("productName", productname);
    formdata.append("id", gid);
    formdata.append("brandName", name);
    formdata.append("price", price);
    formdata.append("significance", significance);
    formdata.append("stock", stock);
    formdata.append("deliveryLeadTime", delleadtime);
    formdata.append("country", country);
    formdata.append("state", state)
    formdata.append("district", district);



    if (images !== "") {
      // for (let i = 0; i < images.length; i++) {
      // console.log(images[i])
      formdata.append('grammerceImage', images)
      // }
    } else {
      formdata.append('grammerceImage', [])
    }

  } else {
    url = "addGrammerce"
    formdata.append("productName", productname);
    formdata.append("brandName", name);
    formdata.append("price", price);
    formdata.append("significance", significance);
    formdata.append("stock", stock);
    formdata.append("deliveryLeadTime", delleadtime);
    formdata.append("country", country);
    formdata.append("state", state)
    formdata.append("district", district);
    if (images !== "") {
      // for (let i = 0; i < images.length; i++) {
      // console.log(images[i])
      formdata.append('grammerceImage', images)
      // }
    } else {
      formdata.append('grammerceImage', [])
    }
  }
  console.log(formdata)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  return api
    .post("adm/" + url, formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ShowAllGrammerce = (pincode: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .get("u/showAllGrammerce?pincode=" + pincode)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const materialPayment = (data: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payinitmultipoojamaterials", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialPaymentMultiRes = (data: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payresmultipoojamaterials", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialOrderUser = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/u/myOrdersMaterials")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

// const materialOrderUser = (usertoken: any) => {
//   const api = axios.create({
//     baseURL: API_URL,
//     headers: { Authorization: `Bearer ${usertoken}` },
//   });
//   return api
//     .get("/u/myOrdersMaterials")
//     .then((response: any) => {
//       return response;
//     })
//     .catch((error) => {
//       return error;
//     });
// }

const grammercePayment = (data: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payinitmultigrammerce", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const grammercePaymentMultiRes = (data: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payresmultigrammerce", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialorderList = (usertoken: any, id: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/ven/showAllOrders?vendorId=" + id)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialorderStatusList = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/ven/getMaterialStatusList")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Materialorderadm = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/showAllMaterialsOrders")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Grammerceorder = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/u/myOrdersGrammerce")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const grammerceorders = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/showAllGrammerceOrders")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const DonationPayment = (token: any, usrId: any, sessionId: any, obj: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", usrId)
  data.append("donationsDto", JSON.stringify(obj))
  return api
    .post("/pay/payInitDonation", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const DonationPaymentMultiRes = (token: any, usrId: any, sessionId: any, obj: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", usrId)
  data.append("multiPurchaseDetails", JSON.stringify(obj))
  return api
    .post("/pay/payResDonations", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Donationmanage = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/showAllDonations")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const DonationmanageSupport = () => {
  const api = axios.create({
    baseURL: API_URL,

  });
  return api
    .post("/adm/showAllDonations")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const DonationOrderUser = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/u/myDonations")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Showallproj = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/adm/showAllProjects")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Showallprojectadm = (usertoken: any, projAdm: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/projectadm/showAllProjects?projAdmId=" + projAdm)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Addproject = (usertoken: any, projecttitle: any, purpose: any, targetamt: any, startdate: any, enddate: any, minamt: any, maxamt: any, projAdmId: any) => {
  var formdata: any = {
    "projectTitle": projecttitle,
    "purpose": purpose,
    "targetAmount": targetamt,
    "startDate": startdate,
    "endDate": enddate,
    "minAmountDonate": minamt,
    "maxAmountDonate": maxamt,
    "projectAdminId": projAdmId
  }
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/projectadm/addNewProject", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      alert("Project Not Added succesfully")
      return error;
    });
}
const Editproject = (usertoken: any, id: any, projecttitle: any, purpose: any, targetamt: any, startdate: any, enddate: any, minamt: any, maxamt: any, admId: any) => {
  var formdata: any = {
    "id": id,
    "projectTitle": projecttitle,
    "purpose": purpose,
    "targetAmount": targetamt,
    "startDate": startdate,
    "endDate": enddate,
    "minAmountDonate": minamt,
    "maxAmountDonate": maxamt,
    "projectAdminId": admId
  }
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/projectadm/updateProject", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      alert("Project Not Added succesfully")
      return error;
    });
}
const ShowAllProjectUser = () => {
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .get("/u/showAllProjects")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ProjectDonationPayment = (data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payinitprojectDonate", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ProjectDonationPaymentMultiRes = (data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/pay/payresprojectDonate", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ProjectDonationOrderUser = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/u/myProjectDonations")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Donationadm = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/showAllProjectDonations")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const Projectdonation = (usertoken: any, id: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/projectadm/showAllProjectDonations?projAdmId=" + id)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const ShowTempleAdmin = (usertoken: any, tempId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("adm/showAllTemples?templeAdminId=" + tempId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const calculateDelGrammerce = (usertoken: any, dataDel: any) => {
  var obj = JSON.stringify(dataDel);
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("u/calcDelcChargeForGrmmaerce", dataDel)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const calculateDelMaterial = (usertoken: any, dataDel: any) => {
  var obj = JSON.stringify(dataDel);
  const api = axios.create({
    baseURL: API_URL,
    //headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("u/calcDelcChargeForPoojaMaterials", dataDel)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const initShipForGrammerce = (usertoken: any, orderId: any, shDate: any, shTime: any, awb: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/initShippmentForGrammrce?orderId=" + orderId + "&scheduleDt=" + shDate + "&scheduleTm=" + shTime + "&awbNumber=" + awb)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const updateOrderStatusForGrammerce = (usertoken: any, data: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/changeGrammerceOrdersStatus", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const initShipForMaterial = (usertoken: any, orderId: any, shDate: any, shTime: any, shId: any, awb: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("ven/initShippmentForPoojaMaterials?orderId=" + orderId + "&scheduleDt=" + shDate + "&scheduleTm=" +
      shTime + "&statusId=" + shId + "&awbNumber=" + awb)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const updateOrderStatusForMaterial = (usertoken: any, data: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("ven/changeMaterialOrdersStatus", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const initShipForOffering = (usertoken: any, orderId: any, shDate: any, shTime: any, shId: any, awb: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("tempadm/initShippmentForPoojaOfferings?orderId=" + orderId + "&scheduleDt=" + shDate + "&scheduleTm=" +
      shTime + "&statusId=" + shId + "&awbNumber=" + awb)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const updateOrderStatusForOffering = (usertoken: any, data: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("tempadm/changePoojaOfferingOrdersStatus", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusAdmUser = (usertoken: any, activeFlag: any, id: any, comm: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("userId", id)
  data.append("activeFlag", activeFlag)
  data.append("comment", comm)
  data.append("sessionId", usertoken)
  return api
    .post("adm/actInactUser", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusVenMat = (usertoken: any, status: any, id: any, comment: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("ven/setPoojaMaterialFlag?materialId=" + id + "&activeFlag=" + status + "&comment=" + comment)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusAdmgram = (usertoken: any, status: any, id: any, comment: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/setGrammerceFlag?grammerceId=" + id + "&activeFlag=" + status + "&comment=" + comment)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusAdmtemp = (usertoken: any, activeFlag: any, id: any, comment: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/setTempleFlag?templeId=" + id + "&activeFlag=" + activeFlag + "&comment=" + comment)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusAdmproj = (usertoken: any, status: any, id: any, comment: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("adm/setProjectStatus?projectId=" + id + "&projectStatus=" + status + "&comment=" + comment)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const statusTempAdmTemple = (usertoken: any, status: any, id: any, comment: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("tempadm/setTempleFlag?templeId=" + id + "&activeFlag=" + status + "&comment=" + comment)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const calDelChargeForOffer = (usertoken: any, data: any) => {

  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("u/calcDelcChargeForOffering", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const pinValid = (pincode: any) => {
  const data = {
    "pincode": pincode
  }
  const api = axios.create({
    baseURL: API_URL
  });
  return api
    .post("u/pinForPoojaOff", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const pinValidGramm = (pincode: any, arr: any) => {
  const data = {
    "pincode": pincode,
    "grammerces": arr
  }
  const api = axios.create({
    baseURL: API_URL
  });
  return api
    .post("u/pincodeValid", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const changeOfferDone = (usertoken: any, id: any, flag: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/tempadm/offeringDone?id=" + id + "&poojaFlag=" + flag)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const Reciept = (usertoken: any, id: any, file: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  var formdata: any = new FormData();
  formdata.append("offeringId", id)
  formdata.append("receiptFile", file)
  return api
    .post("tempadm/uploadReceipt", formdata)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const VerifyMobileChange = (token: any, userToken: any) => {
  var tkn = "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5NjAwMzA1NzY4IiwibW9iaWxlTnVtYmVyIjoiOTYwMDMwNTc2OCIsImVtYWlsIjoic2hhbm11Z2Fwcml5YUBpbmZvY2FyZWVyaW5kaWEuY29tIiwidXNlcklEIjoiNjQ0OTAwZDM5ZjczNzUyYzJjYzgyNDJmIiwicm9sZSI6IlJPTEVfVVNFUiIsImlhdCI6MTY4MjUwNjA4Nn0.4eCxwauvco2APdeyeXGvP2DpCUoU7QFmrOr-A5YUNn4";


  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });

  return api
    .get("user/changeNumberByTkn?resetNumToken=" + token)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
}
const templeAdmList = (usertoken: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("user/ROLE_TEMPADMIN/showAllUserByAdmin")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const vendorAdmList = (usertoken: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("user/ROLE_VENDOR/showAllUserByAdmin")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const projAdmList = (usertoken: any) => {

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("user/ROLE_PROJECTADMIN/showAllUserByAdmin")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const deityActIn = (usertoken: any, id: any, activeFlag: any, comm: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/actInactDeity?id=" + id + "&activeFlag=" + activeFlag + "&comment=" + comm)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const tempadminsort = (usertoken: any, tempName: any, city: any, state: any, district: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/showTempleAdm",
      {
        "templeName": tempName,
        "city": city,
        "country": "India",
        "state": state,
        "district": district
      })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getVendorList = (usertoken: any, state: any, city: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/showVendors", {
      "city": city,
      "country": "India",
      "state": state,
      // "district": city,
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getProjectAdminList = (usertoken: any, state: any, city: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("adm/showProjectAdm", {
      "city": city,
      "country": "India",
      "state": state,
      // "district": city,
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getAllstates = () => {
  const api = axios.create({
    baseURL: API_URL,
  });
  const data: any = new FormData()
  data.append("userId", "")
  data.append("sectionId", null)
  return api
    .post("/u/getAllStates")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getAllDistricts = (item: any) => {
  console.log(item)
  var body: any = {
    "state": item
  }
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/u/allDistricts", body)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const getAllDistrictsByState = (item: any) => {
  console.log(item)
  var body: any = {
    "state": item
  }
  const api = axios.create({
    baseURL: API_URL,
  });
  return api
    .post("/u/districtsByState", body)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const grammercecancel = (usertoken: any, id: any, reason: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("pay/cancelGrammerceOrder", {
      "grammerceOrdId": id,
      "cancelReason": reason
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const grammercereturn = (usertoken: any, id: any, pickupdate: any, pickuptime: any, reason: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("pay/returnGrammerceOrder", {
      "grammerceOrdId": id,
      "scheduleDate": pickupdate,
      "scheduleTime": pickuptime,
      "returnReason": reason
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const initRefundgrammerce = (usertoken: any, id: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("pay/initRefundForGrammerceOrder", {
      "grammerceOrdId": id,
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const grammercereplace = (usertoken: any, id: any, pickupdate: any, pickuptime: any, reason: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("pay/replaceGrammerceOrder", {
      "grammerceOrdId": id,
      "scheduleDate": pickupdate,
      "scheduleTime": pickuptime,
      "replaceReason": reason
    })
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const poojaorderStatusList = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/tempadm/getPoojaStatusList")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadReport = (usertoken: any) => {
  const api = axios.create({
    baseURL: "http://192.168.0.13:8090/",
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("projectadm/projectDonationReport")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const grammerceInvoice = (usertoken: any, paymentId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("u/getInvoiceForOrder/grammerce?paymentId=" + paymentId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const materialInvoice = (usertoken: any, paymentId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("u/getInvoiceForOrder/material?paymentId=" + paymentId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const donationInvoice = (usertoken: any, paymentId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("u/getInvoiceForOrder/donation?id=" + paymentId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const projdonationInvoice = (usertoken: any, paymentId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("u/getInvoiceForOrder/projectDonation?id=" + paymentId)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const getAllRegion = (usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .get("/adm/showAllRegions")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadGrammerceReport = (usertoken: any, data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/grammerceOrdersReport", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadDonationReport = (usertoken: any, data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/adm/donationsReport", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadProjectDonationReport = (usertoken: any, data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/projectadm/projectDonationReport", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadVendorReport = (usertoken: any, data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/ven/materialOrdersReport", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const downLoadTempleReport = (usertoken: any, data: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  return api
    .post("/tempadm/offeringsOrdersReport", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const getAllCarousels = (usertoken: any, userId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("userId", userId)
  data.append("sectionId", usertoken)
  return api
    .post("/adm/getAllCarousels", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getAllCarousellist = (usertoken: any, userId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("userId", userId)
  data.append("sectionId", usertoken)

  return api
    .post("/adm/getCarouselTypeList", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getCarouselsByType = (usertoken: any, type: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("userId", "")
  data.append("carouselType", type)
  data.append("sectionId", usertoken)

  return api
    .post("adm/getCarouselsByType", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const saveCarouselsByType = (usertoken: any, userId: any, obj: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("userId", userId)
  data.append("carouselType", obj.carouselType)
  data.append("description", obj.description)
  data.append("images", obj.images)
  data.append("deletedImg", obj.deletedImg)
  data.append("id", obj.id)
  data.append("sectionId", usertoken)
  data.append("timeInSeconds", obj.timeInSeconds)
  data.append("isActive", obj.isActive)
  data.append("isDeleted", obj.isDelete)

  return api
    .post("adm/saveOrUpdateCarousel", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getAllUserRoles = (usertoken: any, userId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });

  const data: any = new FormData()
  data.append("userId", userId)
  data.append("sessionId", usertoken)
  return api
    .post("adm/getAllUserRoles", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


const getAllUserCarousels = (usertoken: any, type: any, userId: any, logInfo: any) => {
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("carouselType", type)
  data.append("userId", "")
  data.append("sessionId", "")
  data.append("logInfo", logInfo)

  return api
    .post("user/getCarouselsByTypeForUsers", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const getAllTemplesForUsers = (usertoken: any, page: any, userId: any, size: any) => {
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("page", page)
  data.append("userId", userId)
  data.append("sessionId", usertoken)
  data.append("size", size)

  return api
    .post("u/getAllTemplesForUsers", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getTempleDetails = (userToken: any, userId: any, sessionId: any, code: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });
  const formData = new FormData();
  formData.append("userId", userId)
  formData.append("sessionId", sessionId)
  formData.append("templeCode", code)
  return api
    .post("u/getTempleByTempleCode", formData).then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    })
}
const getTempleDetailsAdm = (userToken: any, userId: any, sessionId: any, code: any, isUser: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });
  const formData = new FormData();
  formData.append("userId", userId)
  formData.append("sessionId", sessionId)
  formData.append("templeCode", code)
  var fullUrl = isUser ? "/u/getTempleByTempleCode" : "/tempadm/getTempleByTempleCode"
  return api
    .post(fullUrl, formData).then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    })
}
const getPoojaOfferingDetails = (userToken: any, userId: any, sessionId: any, code: any, searchString: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });
  const formData: any = new FormData();
  formData.append("userId", userId)
  formData.append("sessionId", sessionId)
  formData.append("templeCode", code)
  formData.append("searchString", searchString)
  // formData.append("deityCode", null)


  return api
    .post("u/getTemplePoojaByTempleCode1", formData).then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    })
}

const getAdmAllDistricts = (useId: any, sessionId: any, token: any) => {
  const formData = new FormData()
  formData.append("useId", useId)
  formData.append("sessionId", sessionId)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  return api
    .post("/u/getAllDistricts", formData)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const searchdataRecords = (usertoken: any, searchString: any, userId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  var fullUrl = "u/searchTemp"
  const data: any = new FormData()
  data.append("searchString", searchString)
  data.append("userId", userId)
  data.append("sessionId", usertoken)

  return api
    .post(fullUrl, data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const offerPoojaTemple = (usertoken: any, searchString: any, userId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  var fullUrl = "u/searchForTempleForOfferPooja"
  const data: any = new FormData()
  data.append("searchString", searchString)
  data.append("userId", userId)
  data.append("sessionId", usertoken)

  return api
    .post(fullUrl, data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


const getDeityInfoByObjects = (usertoken: any, userId: any, deityCode: any, deityObject: any) => {
  const api = axios.create({
    baseURL: API_URL,
    // headers: { Authorization: `Bearer ${usertoken}` },
  });
  var fullUrl = "u/getDeityInfoByObjects"
  const data: any = new FormData()
  data.append("deityCode", deityCode)
  data.append("deityObject", deityObject)
  data.append("userId", userId)
  data.append("sessionId", usertoken)

  return api
    .post(fullUrl, data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


const poojaBookingDetails = (userToken: any, userId: any, sessionId: any, data: any) => {
  const formData = new FormData()
  formData.append("userId", userId)
  formData.append("sessionId", userToken)
  formData.append("bookingOrdersDto", JSON.stringify(data))
  console.log(data, "data")
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });
  return api
    .post("/u/saveBookingOrder", formData)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getAllTemplesForAdmin = (usertoken: any, searchString: any, userId: any, role: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  var fullUrl
  fullUrl = "/tempadm/searchTempleForAdm"
  const data: any = new FormData()
  data.append("searchString", searchString)
  data.append("userId", userId)
  data.append("sessionId", usertoken)

  return api
    .post(fullUrl, data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const createandedittemple = (usertoken: any, userId: any, obj: any, img: any, isEdit: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  var fullUrl = isEdit ? "/tempadm/updateTemple" : "/tempadm/createTemple"
  const data: any = new FormData()
  data.append("userId", userId)
  data.append("sessionId", usertoken)
  data.append("createTempleDtoRes", JSON.stringify(obj))
  console.log(img, img.length, "img")
  if (img.length > 0) {
    for (let i = 0; i <= img.length - 1; i++) {
      data.append("imgList", img[i]);
    }
  } else {
    data.append("imgList", []);

  }
  return api
    .post(fullUrl, data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getAllDistrictsAdmin = (useId: any, sessionId: any, token: any) => {
  const formData = new FormData()
  formData.append("useId", useId)
  formData.append("sessionId", sessionId)
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  return api
    .post("/tempadm/getAllDistricts", formData)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const getAllPanchayats = (useId: any, sessionId: any, token: any, districtCode: any) => {
  const formData = new FormData()
  formData.append("useId", useId)
  formData.append("sessionId", sessionId)
  formData.append("districtCode", districtCode)

  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  return api
    .post("/user/getPanchayatsByDistrictCode", formData)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getPoojaOfferingDetailsForAdmin = (userToken: any, userId: any, sessionId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });
  const formData = new FormData();
  formData.append("userId", userId)
  formData.append("sessionId", sessionId)
  return api
    .post("/tempadm/getAllPoojaOfferingsForAdm", formData).then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    })
}
const deleteCarouselImages = (userToken: any, userId: any, sessionId: any, carouselId: any, deletedImg: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });
  const formData = new FormData();
  formData.append("userId", userId)
  formData.append("sessionId", sessionId)
  formData.append("carouselId", carouselId)
  formData.append("deletedImg", deletedImg)


  return api
    .post("/tempadm/deleteCarouselImages", formData).then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    })
}

const conPayinitoffering = (orderId: any, usertoken: any, userId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("orderId", orderId)
  data.append("sessionId", "")
  data.append("userId", userId)
  return api
    .post("/pay/initPayPoojaOfferings", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const conPayresoffering = (details: any, usertoken: any, userId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("multiPurchaseDetails", JSON.stringify(details))
  data.append("sessionId", "")
  data.append("userId", userId)
  return api
    .post("/pay/resPayPoojaOfferings", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const createOrUpdatePoojaOffering = (userToken: any, userId: any, sessionId: any, obj: any,) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });
  const formData = new FormData();
  formData.append("userId", userId)
  formData.append("sessionId", sessionId)
  formData.append("poojaOfferingsDtoReq ", JSON.stringify(obj))



  return api
    .post("/tempadm/createOrUpdatePoojaOffering", formData).then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    })
}


const getAppConfigs = (userToken: any, userId: any, sessionId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });
  const formData = new FormData();
  formData.append("userId", userId)
  formData.append("sessionId", sessionId)

  return api
    .post("/u/getAppConfigs", formData).then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    })
}

const getConfirmedBookingOrders = (usertoken: any, userId: any, sessionId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  return api
    .post("/u/getTempleBooking", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getPendingPrasadamCollection = (usertoken: any, userId: any, sessionId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  return api
    .post("/tempadm/getPendingPrasadamCollection", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const SubmitPoojaBooking = (userToken: any, userId: any, sessionId: any, img: any, bookingNo: any, poojaBooked: any, orderId: any, templeBookingRemark: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });
  console.log(img, "img")
  const formData = new FormData();
  formData.append("userId", userId)
  formData.append("sessionId", sessionId)
  formData.append("img ", img)
  formData.append("bookingNo", bookingNo)
  formData.append("poojaBooked", poojaBooked)
  formData.append("orderId", orderId)
  formData.append("templeBookingRemark", templeBookingRemark)




  return api
    .post("/u/createTempleBooking", formData).then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    })
}

const handlePoojaDeliveryProcess = (userToken: any, userId: any, sessionId: any, img: any, bookingNo: any, isCollection: any, orderNo: any, deliverydetails: any, remark: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });
  const formData = new FormData();
  formData.append("userId", userId)
  formData.append("sessionId", sessionId)
  formData.append("img ", img)
  formData.append("bookingNo", bookingNo)
  formData.append("isCollection", isCollection)
  formData.append("orderNo", orderNo)
  formData.append("deliverydetails", deliverydetails)
  formData.append("remark", remark)


  return api
    .post("/tempadm/handlePoojaDeliveryProcess", formData).then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    })
}

const getAllStars = (userToken: any, userId: any, sessionId: any,) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${userToken}` },
  });
  const formData = new FormData();
  formData.append("userId", userId)
  formData.append("sessionId", sessionId)

  return api
    .post("/u/getAllStars", formData).then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    })
}

const getAdmTempleServiceCharge = (usertoken: any, userId: any, sessionId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  return api
    .post("/tempadm/getAllTempleService", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getUsersForTempleAdmin = (usertoken: any, userId: any, sessionId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  return api
    .post("/tempadm/getUsersForTempleAdmin", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const Showdeity = (usertoken: any, userId: any, sessionID: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const formData: any = new FormData();
  formData.append("userId", userId)
  formData.append("sessionId", sessionID)
  return api
    .post("/u/showAllDeity", formData)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getDeityDetails = (usertoken: any, userId: any, sessionId: any, deityCode: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("deityCode", deityCode)
  return api
    .post("/u/showDeity", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const addOrUpdateDeity = (usertoken: any, userId: any, sessionId: any, deityDto: any, images: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  var url = ""
  if (deityDto.deityCode === "") {
    url = "/adm/addDeity"
  }
  else {
    url = "/adm/updateDeity"
  }
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("deity", JSON.stringify(deityDto))
  if (images.length > 0) {
    for (let i = 0; i < images.length; i++) {
      data.append("deityImages", images[i]);
    }
  } else {
    console.log("else")
    data.append("deityImages", []);
  }
  return api
    .post(url, data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const deleteDeityImg = (usertoken: any, userId: any, sessionId: any, deletedImg: any, carouselId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("deletedImg", deletedImg)
  data.append("carouselId", carouselId)
  return api
    .post("/adm/deleteDeityImg", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getDistrictsDetails = (userId: any, sessionId: any, usertoken: any, districtCode: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("districtCode", districtCode)
  return api
    .post("/u/getDistrictDetailsByDistrictCode", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const addUpdateDistrict = (userId: any, sessionId: any, usertoken: any, districtDetailsDto: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("districtDetailsDto", JSON.stringify(districtDetailsDto))
  return api
    .post("/adm/createOrUpdateDistrictDetails", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getDeityListBySearch = (userId: any, sessionId: any, usertoken: any, searchText: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("searchText", searchText)
  return api
    .post("/u/getTextSearchForDeity", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getAllProductsList = (userId: any, sessionId: any, usertoken: any,) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  return api
    .post("/u/getAllProductsList", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getcalender = (userId: any, sessionId: any, usertoken: any, date: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("date", date)
  return api
    .post("/u/getMalayalamCalander", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getProductByProductCode = (userId: any, sessionId: any, usertoken: any, productCode: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("productCode", productCode)
  return api
    .post("/u/getProductByProductCode", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getAllProductsListAdm = (userId: any, sessionId: any, usertoken: any,) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  return api
    .post("/u/getProductSearch", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getAllProductCategory = (userId: any, sessionId: any, usertoken: any,) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  return api
    .post("/u/getProductCategory", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const addOrUpdateProducts = (usertoken: any, userId: any, sessionId: any, productDto: any, images: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("productDto", JSON.stringify(productDto))
  if (images.length > 0) {
    for (let i = 0; i < images.length; i++) {
      data.append("imgs", images[i]);
    }
  } else {
    console.log("else")
    data.append("imgs", []);
  }
  return api
    .post("/u/createOrUpdateProducts", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const deleteProductImg = (userId: any, sessionId: any, usertoken: any, imageId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("imageId", imageId)
  return api
    .post("/u/deleteProductImage", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getdashboardDetails = (userId: any, sessionId: any, usertoken: any,) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId ? sessionId : "")
  data.append("userId", userId ? userId : "")

  return api
    .post("/user/getDashboardDetails", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getMessagesByCategory = (userId: any, sessionId: any, usertoken: any, category: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("category", category)


  return api
    .post("/user/getMessagesByCategory", data).then((response: any) => {
      const exist = sessionStorage.getItem(category)
      console.log("res=>>>>", response, userId, exist)
      if (response.status === 200 && userId !== null) {
        if (exist && category !== "TempleTransport") {
          return
        } else {
          sessionStorage.setItem(category, category)
          return response
        }
      }
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getTempleReviews = (userId: any, sessionId: any, usertoken: any,) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  return api
    .post("/u/getTempleReviews", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const createOrUpdateTempleReviews = (userId: any, sessionId: any, usertoken: any, templeReviewsDto: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("templeReviewsDto", JSON.stringify(templeReviewsDto))
  data.append("userId", userId)
  return api
    .post("/u/createOrUpdateTempleReviews", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const SubmitRefund = (usertoken: any, userId: any, sessionId: any, obj: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("orderNo", obj.orderNo)
  data.append("bookingNo", obj.bookingNo)
  data.append("refundRemark", obj.refundRemark)
  data.append("refundRef1", obj.refundRef1)
  data.append("refundRef2", obj.refundRef2)
  data.append("refundOn", obj.refundOn)

  return api
    .post("/u/updatePoojaRefund", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getPaidBookingOrders = (usertoken: any, userId: any) => {
  var api;
  const data: any = new FormData()
  data.append("sessionId", usertoken)
  data.append("userId", userId)
  if (usertoken != null && usertoken != undefined && usertoken != "") {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });
  } else {
    api = axios.create({
      baseURL: API_URL,
    });

  }
  return api
    .post("/u/getPaidBookingOrders")
    .then((response: any) => {
      console.log("res=>>>>", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const saveLogDetails = (usertoken: any, userId: any, params: any) => {
  var api;
  const data: any = new FormData()
  data.append("sessionId", usertoken)
  data.append("userId", userId)
  data.append("logDetails", "Transport Details Clicked")
  data.append("menuItem", "Transport Details")
  data.append("parameter", params)
  if (usertoken != null && usertoken != undefined && usertoken != "") {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });
  } else {
    api = axios.create({
      baseURL: API_URL,
    });

  }
  return api
    .post("/user/saveLogDetails")
    .then((response: any) => {
      console.log("res=>>>>", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const createOrUpdateTemplePoojaList = (usertoken: any, userId: any, templeCode: any, dataAtr: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", usertoken)
  data.append("userId", userId)
  data.append("templeCode", templeCode)
  data.append("templepoojaDtos", JSON.stringify(dataAtr))

  return api
    .post("/tempadm/createOrUpdateTemplePoojaList", data)
    .then((response: any) => {
      console.log("res=>>>>", response)
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const deleteTemplePooja = (userId: any, sessionId: any, usertoken: any, tempId: any, PoojaId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("templeCode", tempId)
  data.append("poojaCode", PoojaId)
  return api
    .post("/tempadm/deleteTemplePooja", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getMailLink = (userId: any, sessionId: any, usertoken: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  return api
    .post("/user/getVerificationLink", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getUserInfo = (token: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  const data: any = new FormData()
  data.append("token", token)
  return api
    .post("/user/getUserInfo", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}


const getEmailOtp = (userId: any, sessionId: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  return api
    .post("/user/getEmailOtp", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const verifyotp = (mailId: any, otp: any, userId: any, sessionId: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("otp", otp)
  data.append("email", mailId)

  return api
    .post("/user/validateEmailOpt", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const isEmailVerified = (userId: any, sessionId: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)

  return api
    .post("/user/isEmailVerified", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}

const getAllReviewsList = (usertoken: any, userId: any, sessionId: any, fdate: any, tdate: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("fromDate", fdate)
  data.append("toDate", tdate)
  return api
    .post("/tempadm/getReviewsListByDate", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const saveTempleFollowsUp = (usertoken: any, userId: any, sessionId: any, code: any, menu: any, msg: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("templeCode", code)
  data.append("logInfo", msg)
  data.append("menuButton", menu)
  return api
    .post("/user/saveTempleFollowsUp", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const updateFundForDisbursed = (usertoken: any, userId: any, sessionId: any, bookingOrderNo: any, fundRef1: any, fundRef2: any, paymentOrderNo: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("bookingOrderNo", bookingOrderNo)
  data.append("fundRef1", fundRef1)
  data.append("fundRef2", fundRef2)
  data.append("paymentOrderNo", paymentOrderNo)
  return api
    .post("/pay/updateFundForDisbursed", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getFundstoVolunteer = (usertoken: any, userId: any, sessionId: any, fdate: any, tdate: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("fromDate", fdate)
  data.append("toDate", tdate)
  return api
    .post("/tempadm/getFundstoVolunteer", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const getuserbookingOrders = (usertoken: any, userId: any) => {
  var api;
  const data: any = new FormData()
  data.append("sessionId", usertoken)
  data.append("userId", userId)
  if (usertoken != null && usertoken != undefined && usertoken != "") {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });
  } else {
    api = axios.create({
      baseURL: API_URL,
    });

  }
  return api
    .post("/u/getUsersOrders")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const getBookingStatus = (usertoken: any, userId: any) => {
  var api;
  const data: any = new FormData()
  data.append("sessionId", usertoken)
  data.append("userId", userId)
  if (usertoken != null && usertoken != undefined && usertoken != "") {
    api = axios.create({
      baseURL: API_URL,
      headers: { Authorization: `Bearer ${usertoken}` },
    });
  } else {
    api = axios.create({
      baseURL: API_URL,
    });

  }
  return api
    .post("/user/getBookingStatus")
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


const deleteUserAcc = (usertoken: any, status: any, userId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", usertoken)
  data.append("userId", userId)
  data.append("isDelete", status)
  return api
    .post("/adm/deleteUser", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

const getVendorsList = (usertoken: any, status: any, userId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", usertoken)
  data.append("userId", userId)
  return api
    .post("/u/getVendorsList", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
const getAllTempleSearchOf = (usertoken: any, userId: any) => {
  const api = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${usertoken}` },
  });
  const data = new FormData()
  data.append("sessionId", usertoken)
  data.append("userId", userId)
  return api
    .post("/user/getAllTempleSearchOf", data)
    .then((response: any) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}


const VerigyMailuser = (userId: any, sessionId: any, mail: any) => {
  const api = axios.create({
    baseURL: API_URL,
  });
  const data: any = new FormData()
  data.append("sessionId", sessionId)
  data.append("userId", userId)
  data.append("flag", true)
  data.append("email", mail)
  return api
    .post("/user/verifyEmail", data).then((response: any) => {
      return response
    })
    .catch((error: any) => {
      return error
    })
}






const Authservice = {
  myProfile,
  Profileupdate,
  ChangePassword,
  Showallusers,
  Showdeity,
  updateDeity,
  Viewtemple,
  Edittemple,
  updateTemple,
  ShowdeitTemp,
  createTemple,
  Poojaofferings,
  AddToCartInit,
  Addcart,
  payInit, MyOrder,
  OrderTemp,
  ShowAllOrder,
  removeCart,
  Materialadm,
  Viewmaterialadm,
  materialList,
  addMaterial,
  ShowAllPoojaMaterial,
  Grammerceadm,
  Viewgrammerceadm,
  updategrammerce,
  ShowAllGrammerce,
  materialPayment,
  materialOrderUser,
  grammercePayment,
  materialorderList,
  Grammerceorder,
  Materialorderadm,
  grammerceorders,
  DonationPayment,
  Donationmanage,
  DonationOrderUser,
  Showallprojectadm,
  Showallproj,
  Addproject, ShowAllProjectUser,
  ProjectDonationPayment,
  ProjectDonationOrderUser,
  Donationadm,
  Projectdonation,
  grammercePaymentMultiRes,
  materialPaymentMultiRes,
  DonationPaymentMultiRes,
  ProjectDonationPaymentMultiRes,
  payOfferingRes,
  DonationmanageSupport,
  ShowTempleAdmin,
  calculateDelGrammerce,
  calculateDelMaterial,
  initShipForGrammerce,
  updateOrderStatusForGrammerce,
  initShipForMaterial,
  updateOrderStatusForMaterial,
  initShipForOffering,
  updateOrderStatusForOffering,
  statusAdmUser,
  statusVenMat,
  statusAdmgram,
  statusAdmtemp,
  statusAdmproj,
  statusTempAdmTemple,
  Editproject,
  calDelChargeForOffer,
  changeOfferDone,
  Reciept,
  ChangeNumber,
  VerifyMobileChange,
  templeAdmList,
  vendorAdmList,
  projAdmList,
  Payinitoffering,
  Payresoffering,
  deityActIn,
  formatPhone,
  tempadminsort,
  getVendorList,
  getProjectAdminList,
  getAllstates,
  getAllDistricts,
  pinValid,
  pinValidGramm,
  getAllDistrictsByState,
  grammercecancel,
  grammercereturn,
  initRefundgrammerce,
  grammercereplace,
  getApiUrl,
  mapKey, materialorderStatusList,
  poojaorderStatusList, downLoadReport,
  grammerceInvoice,
  materialInvoice,
  donationInvoice,
  projdonationInvoice,
  getAllRegion,
  downLoadGrammerceReport, downLoadDonationReport,
  downLoadProjectDonationReport,
  downLoadVendorReport,
  downLoadTempleReport,
  getAllCarousels,
  getAllCarousellist,
  getCarouselsByType,
  saveCarouselsByType,
  getAllUserRoles,
  getAllUserCarousels,
  getTempleDetails,
  getAllTemplesForUsers,
  getPoojaOfferingDetails,
  getAdmAllDistricts,
  searchdataRecords,
  poojaBookingDetails,
  getAllTemplesForAdmin,
  createandedittemple,
  getAllDistrictsAdmin,
  getAllPanchayats,
  getTempleDetailsAdm,
  getPoojaOfferingDetailsForAdmin,
  deleteCarouselImages,
  conPayinitoffering,
  conPayresoffering,
  createOrUpdatePoojaOffering,
  getAppConfigs,
  getConfirmedBookingOrders,
  SubmitPoojaBooking,
  getAllStars,
  getAdmTempleServiceCharge,
  getUsersForTempleAdmin,
  getDeityDetails,
  addOrUpdateDeity,
  deleteDeityImg,
  getPendingPrasadamCollection,
  handlePoojaDeliveryProcess,
  getDistrictsDetails,
  addUpdateDistrict,
  getDeityListBySearch,
  getAllProductsList,
  getProductByProductCode,
  getAllProductsListAdm,
  getAllProductCategory,
  addOrUpdateProducts,
  deleteProductImg,
  getcalender,
  getdashboardDetails,
  getMessagesByCategory,
  getTempleReviews,
  createOrUpdateTempleReviews,
  SubmitRefund,
  getPaidBookingOrders,
  saveLogDetails,
  offerPoojaTemple,
  getDeityInfoByObjects,
  createOrUpdateTemplePoojaList,
  deleteTemplePooja,
  getMailLink,
  getUserInfo,
  getEmailOtp,
  verifyotp,
  isEmailVerified,
  getAllReviewsList,
  saveTempleFollowsUp,
  updateFundForDisbursed,
  getFundstoVolunteer,
  getuserbookingOrders,
  getBookingStatus,
  deleteUserAcc,
  getVendorsList,
  getAllTempleSearchOf,
  VerigyMailuser



};
export default Authservice;
