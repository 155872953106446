import { IonAlert, IonButton, IonCheckbox, IonCol, IonContent, IonIcon, IonImg, IonPage, IonRow } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import HeadFoot from "./Head";
import DataTable from "react-data-table-component";
import CommonDataTable from "../components/datatable";
import { data } from "jquery";
import Foot from "./Foot";
import Authservice from "../Services/user.service";
import UserService from "../Services/auth.service";
import { Button, Form, Modal } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { set } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons'; // Example icons
import AdminHead from "./admin/adminHead";
import Sidebar from "./admin/sidebar";
import CommonModal from "../components/popipInfo";


const PrasadhamDelivery: React.FC = () => {

    const [usertoken] = useState(sessionStorage.getItem("token"));
    const [userId] = useState(sessionStorage.getItem("UserId"));
    const [showLoading, setShowLoading] = useState(false);
    const [tabledata, settabledata]: any = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showlightBox, setShowLightBox] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedImage, setIsCheckedImage] = useState(false);
    const [selectedImage, setSelectedImage]: any = useState(null);
    const imageref: any = useRef(null)
    const [image, setImage] = useState("");
    const [images, setImages]: any = useState("");
    const [radioValue, setRadioValue]: any = useState("Yes");
    const [userRole] = useState(sessionStorage.getItem("UserData"));

    const [cameraOpen, setCameraOpen] = useState(false);
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [rowItem, setRowItem]: any = useState({})
    const [iserror, setiserror]: any = useState()
    const [title, setTitle]: any = useState()
    const [message, setMessage]: any = useState("")
    const [remarks, setremarks] = useState()
    const [details, setDetails] = useState("")
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const [Commonmodal, setCommonmodal] = useState(false)
    const [CommonModalMesg, setCommonModalMesg] = useState("")
    const [Commmonmodaltitle, setCommonmodaltitle] = useState("")



    useEffect(() => {
        getPoojaOfferingDetailsForAdmin()
    }, [])


    const getPoojaOfferingDetailsForAdmin = () => {
        Authservice.getPendingPrasadamCollection(usertoken, userId, usertoken).then((response: any) => {
            console.log(response.data);
            settabledata(response.data)
            setShowLoading(false);
        })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });
    }


    const modalClose = () => {
        if (Commmonmodaltitle === "Information") {
            window.location.reload()
        } else {
            setCommonmodal(false)
        }
    }


    // const handleCheckboxChange = (poojaId: any) => {
    //     console.log(poojaId)
    //     setSelectedOrders((prev: number[]) => {
    //         const isSelected = prev.includes(poojaId.id);
    //         console.log(`Pooja ID: ${poojaId.id}, Already Selected: ${isSelected}`);
    //         return isSelected
    //             ? prev.filter((id) => id !== poojaId.id) // Remove
    //             : [...prev, poojaId.id];                // Add
    //     });
    // };

    const columns = [
        {
            name: 'Order No',
            selector: (row: any) => row.OrderNo,
        },
        {
            name: 'Collection On After',
            selector: (row: any) => formatDate(row.CollectionOnAfter),
            sortable: true,
        },
        {
            name: 'Pooja Name',
            selector: (row: any) => row.PoojaFor,
        },
        // {
        //     name: 'Ordered By',
        //     selector: (row: any) => row.OrderedBy,
        //     sortable: true,
        // },
        {
            name: 'Pooja Description',
            selector: (row: any) => row.PoojaDescription,
        },
        {
            name: 'Ordered By',
            selector: (row: any) => row.OrderedByName,
        },

        {
            name: 'Prasadam Required',
            selector: (row: any) => row.PrasadamRequired === "1" ? "Yes" : "No",
            sortable: true,
        },

        // {
        //     name: 'Order Date',
        //     selector: (row:any)=> formatDate(row.OrderDate),
        //     sortable: true,
        // }
    ];


    const formatDate = (timestamp: any) => {
        console.log(timestamp)
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };




    const conditionalRowStyles = [
        {
            when: (row: any) => row.isUserHeader, // Highlight user rows
            style: {
                backgroundColor: "#e3eeff",
                fontWeight: "bold",
                borderBottom: "1px solid #d3d3d3",
            },
        },
    ];

    const handleRowClick = (row: any) => {
        console.log(row)
        setRowItem(row)
        setImage("")
        setImages("")
        setRadioValue("Yes")
        setShowModal(true);
    };

    // Handle checkbox change
    const handleCheckboxChange = (e: any, num: any) => {
        console.log(num)
        if (num === "Yes" || num === "No") {
            setRadioValue(num);
        } else if (num === "details") {
            setDetails(e.target.value)
        } else {
            setremarks(e.target.value)
        }
    }

    // Handle file input change
    const handleFileChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedImage(e.target.files[0]);
        }
    };

    // Submit the selected image
    const handleSubmit = () => {
        if (!images) {
            alert('Please select an image to upload.');
            return;
        }
        else if (radioValue === "No" && !remarks) {
            alert('Please enter the remarks.');
            return;
        }
        const radio = radioValue === "Yes" ? true : false
        Authservice.handlePoojaDeliveryProcess(usertoken, userId, usertoken, images, rowItem.BookingDetailNumber, radio, rowItem.OrderNo, details, remarks).then((response: any) => {
            console.log(response.data);
            if (response.data === "success") {
                setCommonmodal(true)
                setCommonModalMesg("Updated Successfully")
                setCommonmodaltitle("Information")
                setShowModal(false);
            }
        })
            .catch((error: any) => {
                console.error("Error fetching data:", error);
                setShowLoading(false);
            });

    };

    const handleButtonClick = () => {
        if (imageref.current) {
            imageref.current.click();
        }
    };


    const handleimage = (e: any) => {
        var file: any = e.target.files[0];
        const splited: any = file.name.split(".")
        if (splited[splited.length - 1] === "jpg" || splited[splited.length - 1] === "png" || splited[splited.length - 1] === "jpeg") {
            console.log(selectedImage)
            const objectUrl = URL.createObjectURL(file);
            setImages(file);
            setImage(objectUrl);
        } else {
            if (imageref.current) {
                imageref.current.value = null;
            }
            setCommonmodal(true)
            setCommonModalMesg("Unsupported File Format")
            setCommonmodaltitle("Error")
            setShowModal(false);
        }
    };

    const dataURItoFile = (dataURI: any, fileName: any) => {
        // Extract the mime type from the Data URI
        let arr = dataURI.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    };


    const openCamera = async () => {
        setShowModal(false)
        try {
            const image: any = await Camera.getPhoto({
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.DataUrl, // Get the photo as a data URL for web compatibility
                source: CameraSource.Camera, // Opens the camera directly
            });
            console.log(image)
            setImage(image.dataUrl!); // Set the captured image
            const file = dataURItoFile(image.dataUrl, "captured-image.jpg");
            setImages(file);
            setShowModal(true)
        } catch (error) {
            console.error('Camera error:', error);
            setShowModal(true)
        }
    };


    const CardViewRow = ({ data }: any) => {
        const [isOpen, setIsOpen] = useState(false);
        const toggleDetails = () => {
            setIsOpen(!isOpen);
        };
        console.log(data)
        return (
            <div className="newCardV2 card">
                <div className="card-header-v2">
                    <div className="card-title">
                        <strong>Order Number: </strong>{data.OrderNo}
                    </div>
                    <div className="dropdown-icon" onClick={toggleDetails}>
                        <IonIcon icon={isOpen ? chevronUpOutline : chevronDownOutline} />
                    </div>
                </div>

                <div className="card-body">
                    <div className="card-row">
                        <strong>Temple Name: </strong> {data.TempleCode}
                    </div>
                    <div className="card-row">
                        <strong>Ordered By: </strong> {data.FirstName || 'N/A'}
                    </div>
                    <div className="card-row">
                        <strong>Pooja Amount: </strong> {data.PoojaAmount}
                    </div>
                    <div className="card-row">
                        <strong>Charges: </strong> {data.Charges}
                    </div>
                </div>
            </div>
        );
    };
    const dismissAlert = () => {
        if (title === "Success") {
            getPoojaOfferingDetailsForAdmin()
        } else {
            setiserror(false)
            setShowModal(true)
        }
    }

    return (
        <IonPage>
            {userRole === "ROLE_ADMIN" && <>
                <AdminHead User={"user"} Role={"Role"} Name={"Prasadham Delivery"} />
                <Sidebar /></>}
            <IonContent>
                {userRole !== "ROLE_ADMIN" && <HeadFoot User={"login"} Role="Role" Name='Home,Prasadham Delivery' />}
                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => dismissAlert()}
                    cssClass="my-custom-class"
                    header={title}
                    message={message}
                    buttons={["Dismiss"]}
                />
                <div className={userRole === "ROLE_ADMIN" ? "container_middle_table page-wrapper admin_background_color" : "page_content_padding tableContainev2"}>
                    {/* {isDesktop ? */}
                    <CommonDataTable
                        columns={columns}
                        data={tabledata}
                        //  loading={showLoading}
                        noDataMessage="Sorry, No data available"
                        onRowClick={handleRowClick}
                        conditionalRowStyles={conditionalRowStyles}
                        selectableRows={false}
                    />
                    {/* : <>
                            {tabledata.map((row: any) => (
                                <CardViewRow key={row.id} data={row} />
                            ))}</>} */}
                </div>
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    aria-labelledby="image-upload-modal"
                    className="expandable-row-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="image-upload-modal">Confirm Delivery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-column p-2 radio_checkbox_container">
                            {/* Radio Options */}
                            <div className="mb-3 d-flex align-items-center">
                                <label htmlFor="poojaBooked" className="label-fonts me-3" style={{ fontWeight: "bold" }}>
                                    Collect & Dispatch
                                </label>
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        type="radio"
                                        id="poojaBookedYes"
                                        name="poojaBooked"
                                        value="Yes"
                                        checked={radioValue === "Yes"}
                                        onChange={(e) => handleCheckboxChange(e, "Yes")}
                                    />
                                    <label htmlFor="poojaBookedYes">Yes</label>
                                    <input
                                        type="radio"
                                        id="poojaBookedNo"
                                        name="poojaBooked"
                                        value="No"
                                        checked={radioValue === "No"}
                                        onChange={(e) => handleCheckboxChange(e, "No")}
                                    />
                                    <label htmlFor="poojaBookedNo">No</label>
                                </div>
                            </div>

                            {/* Remarks */}
                            <div className="mb-3 d-flex align-items-center">
                                <label htmlFor="remarks" className="label-fonts me-3" style={{ fontWeight: "bold" }}>
                                    Details
                                </label>
                                <textarea
                                    id="details"
                                    className="temple_inputs temple_textArea flex-grow-1"
                                    style={{ minHeight: "50px" }}
                                    value={details}
                                    onChange={(e) => handleCheckboxChange(e, "details")}
                                />
                            </div>

                            <div className="mb-3 d-flex align-items-center">
                                <label htmlFor="remarks" className="label-fonts me-3" style={{ fontWeight: "bold" }}>
                                    Remarks
                                </label>
                                <textarea
                                    id="remarks"
                                    className="temple_inputs temple_textArea flex-grow-1"
                                    style={{ minHeight: "50px" }}
                                    value={remarks}
                                    onChange={(e) => handleCheckboxChange(e, "remarks")}
                                />
                            </div>

                            {/* File Input and Image Preview */}
                            <div className="mb-3 d-flex flex-column">
                                <div className="d-flex gap-2">
                                    <IonButton
                                        className="admin_add_btn"
                                        onClick={handleButtonClick}
                                        type="button"
                                    >
                                        Upload Receipt Copy
                                    </IonButton>
                                    <IonButton
                                        className="admin_add_btn"
                                        onClick={openCamera}
                                        type="button"
                                    >
                                        Open Camera
                                    </IonButton>
                                </div>

                                {image.length > 0 && (
                                    <div className="adm_image_container mt-3">
                                        <IonImg
                                            className="image_design_deity"
                                            src={image}
                                            onClick={() => setShowLightBox(true)}
                                        />
                                    </div>
                                )}

                                <input
                                    type="file"
                                    ref={imageref}
                                    onChange={(e) => handleimage(e)}
                                    style={{ display: "none" }}
                                />
                            </div>

                            {/* Submit and Cancel Buttons */}
                            <div className="d-flex justify-content-end gap-2">
                                <IonButton
                                    className="admin_add_btn"
                                    onClick={handleSubmit}
                                    type="submit"
                                >
                                    Submit
                                </IonButton>
                                <IonButton
                                    className="admin_cencel_btn"
                                    fill="outline"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </IonButton>
                            </div>
                        </div>

                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={handleSubmit}
                            disabled={!isChecked || !selectedImage}
                        >
                            Submit
                        </Button>
                    </Modal.Footer> */}
                </Modal>
                {showlightBox && (
                    <Lightbox
                        mainSrc={image}
                        onCloseRequest={() => setShowLightBox(false)}
                        onImageLoad={() => {
                            window.dispatchEvent(new Event('resize'));
                        }}
                    />
                )}
                {userRole !== "ROLE_ADMIN" && <Foot />}
                <CommonModal showModal={Commonmodal} closeModal={modalClose} modaltitle={Commmonmodaltitle} AlertModalMesg={CommonModalMesg} />
            </IonContent>
        </IonPage>
    )
}

export default PrasadhamDelivery;